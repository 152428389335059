import React, { Component } from 'react'
import hoverEffect from 'hover-effect'

import('./hover-effect.sass')

export default class HoverEffect extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const parent = this.refs.AppicHoverEffect;
    new hoverEffect({
      parent: parent,
      intensity: 0.3,
      image1: this.props.showImage,
      image2: this.props.hoverShowImage,
      displacementImage: this.props.displacementImage,
      imagesRatio: 3/4
    })
  }


  renderModalChild() {
    return (
      <div className="hover-effect-is-modal-child">
        <div className="hover-effect-is-modal-title">{ this.props.title }</div>
        <div ref="AppicHoverEffect" className="appic-hover-effect-photo is-modal" id="appic-hover-effect" />
        <div className="hover-effect-is-modal-address">Address: { this.props.address }</div>
        <div className="hover-effect-is-modal-desc">
          { this.props.desc }
        </div>
      </div>
    )
  }

  renderChild() {
    return (
      <div>
        <div ref="AppicHoverEffect" className="appic-hover-effect-photo" id="appic-hover-effect" />
        <div className="appic-hover-effect-desc">
          <div>{ this.props.title }</div>
          <div>{ this.props.address }</div>
        </div>
      </div>
    )
  }


  render() {
    const { isModal } = this.props;
    return (
      <div className="appic-hover-effect-container" onClick={this.props.onClickShowModal}>
        {
          isModal ? this.renderModalChild() : this.renderChild()
        }
      </div>
    )
  }
}
