import React from "react"
import AppicPlayContext from '../../context/ApplyPlayContext'
import HomeComponents from "../HomeComponents/HomeComponents"
import PublishComponents from "../PublishComponents/PublishComponents"
import AboutComponents from "../AboutComponents/AboutComponents"
import PrivacyPolicyComponents from "../PrivacyPolicyComponents/PrivacyPolicyComponents"
import TermsOfServiceComponents from "../TermsOfServiceComponents/TermsOfServiceComponents"
import("./main.scss")

const Main = props => {
  const pathname = props.location.pathname
  const { location } = props
  let type = 8888
  switch (pathname) {
    case "/":
      type = 0
      break
    case "/publish/":
      type = 1
      break
    case "/about/":
      type = 2
      break
    case "/privacy-policy/":
      type = 3
      break
    case "/terms-of-service/":
      type = 4
      break
    default:
      type = 0
      break
  }
  return (
    <AppicPlayContext.Consumer>
      {
        appicplay => (
          type === 0 ? (
            <HomeComponents appicplay={appicplay} />
          ) : type === 1 ? (
            <PublishComponents appicplay={appicplay} />
          ) : type === 2 ? (
            <AboutComponents appicplay={appicplay} />
          ) : type === 3 ? (
            <PrivacyPolicyComponents appicplay={appicplay, location} />
          ) : type === 4 ? (
            <TermsOfServiceComponents appicplay={appicplay, location} />
          ) : (
            <div>页面加载失败</div>
          ))
      }
    </AppicPlayContext.Consumer>
  )
}

export default Main
