import React from "react"
import ReactComment from "../ReactComment/ReactComment"
import("./back-top.scss")

const BackTop = () => {
  const toTop = () => {
    if (document.documentElement.scrollTop === 0) {
      return
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <div className="appic-back-top" onClick={toTop}>
      <ReactComment text="back-top-components" />
      <span>&#8593;</span>
    </div>
  )
}

export default BackTop
