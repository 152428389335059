import React from "react"
import ReactComment from "../ReactComment/ReactComment"
import HotGames from "../HotGames/HotGames"
import TopGames from "../TopGames/TopGames"
import MoreCompany from "../MoreCompany/MoreCompany"
import FriendShip from "../FriendShip/FriendShip"
import GamesArea from "../GamesArea/GamesArea"
import Address from "../Address/Address"

const HomeComponents = ({ appicplay }) => {
  return (
    <div id="home">
      <ReactComment text={"hot-games"} />
      <HotGames appicplay={appicplay} />
      <ReactComment text={"top-games"} />
      <TopGames appicplay={appicplay} />
      <ReactComment text={"more-company"} />
      <MoreCompany appicplay={appicplay} />
      <ReactComment text={"friend-ship"} />
      <FriendShip appicplay={appicplay} />
      <ReactComment text={"games-area"} />
      <GamesArea appicplay={appicplay} />
      <ReactComment text={"address"} />
      <Address appicplay={appicplay} />
    </div>
  )
}

export default HomeComponents
