import React from 'react'
import cloneDeep from 'clonedeep'
import HoverEffect from '../HoverEffect/HoverEffect'
// import { useModal, Modal } from 'react-morphing-modal';
// import 'react-morphing-modal/dist/ReactMorphingModal.css';
import LazyLoadSvg from '../LazyLoad/LazyLoadSvg'
// import useUnmount from '../ReactUse/useUnmount'
import useMount from '../ReactUse/useMount'
import('./our-team.sass')

// const OurTeams = ({ appicplay }) => {
//   // const { modalProps, getTriggerProps, activeModal } = useModal({background: 'rgba(255, 255, 255, .9)'});
//   const { appicplay: data } = appicplay
//   const { about, siteProps } = data
//   const BaseOssUrl = typeof siteProps !== `undefined` && (siteProps.url + siteProps.folder)
//   let TeamData = {pics:[]};

//   useMount(() => {
//     TeamData = {pics:[]}
//   });
//   if (typeof about !== `undefined`) {
//     if (typeof about.teamData !== `undefined`) {
//       if (typeof about.teamData.pics !== `undefined`) {
//         // console.log(about.teamData)
//         const _cache = cloneDeep(about.teamData.pics);

//           TeamData['pics'] =  _cache.map((item) => {
//             item.show = BaseOssUrl + item.show;
//             item.hoverShowImage = BaseOssUrl + item.hoverShowImage;
//             item.displacementImage = BaseOssUrl + item.displacementImage;
//             return item;
//           })
//           console.log(TeamData)

//       }
//     }
//   }


//   // let currentModalShowPics = {}

//   // if (activeModal !== null) {
//   //   const item = TemaData.pics.find(it => it.id === activeModal);
//   //   currentModalShowPics = Object.assign({}, item)
//   // } else {
//   //   currentModalShowPics = {}
//   // }

//   return (
//     <div id='appic-OurTeam' className="appic-about-our-team">
//        <div className="container">
//          <div className="section">
//            <div className="appic-about-our-team-title has-text-centered is-size-1 is-font-bebaskai">
//              Our&nbsp;&nbsp;Team
//            </div>
//            {
//            }
//            <div className="columns is-item-container is-multiline">
//                 {
//                     TeamData.pics.length>0 ? TeamData.pics.map((item, idx) => {
//                       return (
//                         <div  key={`is-item-effect-${idx}`} className="column is-4 is-item-effect">
//                           <HoverEffect
//                             showImage={item.show}
//                             hoverShowImage={item.hoverShowImage}
//                             displacementImage={item.displacementImage}
//                             address={item.address}
//                             title={item.title}
//                             isModal={false}
//                           />
//                         </div>
//                       )
//                     })
//                     :
//                     <LazyLoadSvg />
//                   }
//            </div>
//          </div>
//        </div>
//        {/* <Modal {...modalProps}>
//          <div className="appic-is-modal" style={{ width: '100%', height: '600px',  }}>
//            {
//              currentModalShowPics.id !== void 0
//              ?
//              <HoverEffect
//                 showImage={currentModalShowPics.show}
//                 hoverShowImage={currentModalShowPics.hoverShowImage}
//                 displacementImage={currentModalShowPics.displacementImage}
//                 address={currentModalShowPics.address}
//                 title={currentModalShowPics.title}
//                 desc={currentModalShowPics.desc}
//                 isModal={true}
//               />
//              :
//              ''
//            }

//          </div>
//       </Modal> */}
//     </div>
//   )
// }

export default class OurTeam extends React.Component {

  constructor(props) {
    super(props)
    console.log(this.props)
    this.state = {
      TeamData: {
        pics: []
      }
    }
  }

  componentDidMount() {
    const { appicplay } = this.props.appicplay
    const { about, siteProps } = appicplay
    const BaseOssUrl = typeof siteProps !== `undefined` && (siteProps.url + siteProps.folder)
    const _cache = cloneDeep(about.teamData.pics);
    let _item =  _cache.map((item) => {
      item.show = BaseOssUrl + item.show;
      item.hoverShowImage = BaseOssUrl + item.hoverShowImage;
      item.displacementImage = BaseOssUrl + item.displacementImage;
      return item;
    })
    this.setState({
      TeamData: {
        pics: _item
      }
    })
  }


  render() {
        return (
          <div id='appic-OurTeam' className="appic-about-our-team">
             <div className="container">
               <div className="section">
                 <div className="appic-about-our-team-title has-text-centered is-size-1 is-font-bebaskai">
                   OUR TEAM AT WORK, AT PLAY, and MEETING CLIENTS
                 </div>
                 {
                 }
                 <div className="columns is-item-container is-multiline">
                      {
                          this.state.TeamData.pics.length > 0 ? this.state.TeamData.pics.map((item, idx) => {
                            return (
                              <div  key={`is-item-effect-${idx}`} className="column is-4 is-item-effect">
                                <HoverEffect
                                  showImage={item.show}
                                  hoverShowImage={item.hoverShowImage}
                                  displacementImage={item.displacementImage}
                                  address={item.address}
                                  title={item.title}
                                  isModal={false}
                                />
                              </div>
                            )
                          })
                          :
                          <LazyLoadSvg />
                        }
                 </div>
               </div>
             </div>
          </div>
        )
  }
}
