import React from 'react'
import('./product.sass')

const Product = () => {
  return (
    <div id="product" className="appic-publish-product">
      <div className="container bottom-con">
        <div className="section">
          <div className="columns">
            <div className="column">
              <div className="columns is-multiline appic-publish-product-content">
                <div className="column is-12">
                  <p className="is-uppercase is-size-1 is-size-4-mobile is-font-bebaskai">MONEY TALKS</p>
                  <p className="is-size-6-mobile is-font-ubuntu">As a publisher of casual, ad supported games, one of the first things we built alongside one hundred or more marketing channels was our proprietary ad tech. Now more than 3 years in, this part of our business has a life of its own, powering well more than 50 million DAU of 3rd party apps and games globally. If you have China users at scale, you should probably talk to us to see if we can help. In most cases, You do not need to give up your global mediation or existing partners to do so.</p>
                </div>
                <div className="column is-12">
                  <p className="is-uppercase is-size-1 is-size-4-mobile is-font-bebaskai">Make More, Work Less</p>
                  <p className="is-size-6-mobile is-font-ubuntu">As a team that has led the publishing and monetization of a major hit game, we know that managing ad monetization can be a pain for many teams. Our solution therefore simple and effective, and allows you to focus on your core business, making and or publishing great content. If we can help, let us know!</p>
                </div>
              </div>
            </div>
            <div className="column">
              <p className="is-uppercase is-size-1 is-size-4-mobile has-text-centered has-text-left-mobile is-font-bebaskai">CHINA AD <span>MEDIATION</span> <span>&</span> NETWORK</p>
              <figure className="image is-480x480">
                <img src={require('../../images/production_logos.png')} alt="logos" />
              </figure>
            </div>
          </div>
          {/* pc */}
          <div className="appic-publish-product-content-pc has-text-centered is-hidden-mobile">
            <p className="appic-publish-product-content-pc_title has-text-left is-uppercase is-size-1 is-font-bebaskai">Our China <span>Working</span> Partners</p>
            <img src={require('../../images/pic_customer.png')} alt="pic_customer" />
          </div>
          {/* mobile */}
          <div className="appic-publish-product-content-mobile is-hidden-tablet">
            <p className="is-size-4 appic-publish-product-content-mobile_title has-text-left is-uppercase has-text-weight-semibold">Our China <span>Working</span> Partners</p>
            <p className="is-size-4 mobile-tag">CLIENTS</p>
            <img src={require('../../images/client-mobile.png')} alt="client-mobile" />
            <p className="is-size-4 mobile-tag">MEDIA</p>
            <img src={require('../../images/media-mobile.png')} alt="media-mobile" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
