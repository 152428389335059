import React from 'react'
import('./loading.sass')

const LazyLoadSvg = ({
  isCyan = false,
 }) => {
  return (
    <div id='appic-LazyLoadSvg'>
      <div className={`indicator swiper-lazy-preloader ${isCyan ? 'is-cyan' : ''}`}>
        <svg width="32px" height="24px">
          <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
          <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
        </svg>
      </div>
    </div>
  )
}

export default LazyLoadSvg
