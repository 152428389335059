import React from 'react'
import { Link } from 'gatsby'
import('./repeat-business.sass')

const RepeatBusiness = () => {
  return (
    <div className="appic-publish-repeat-business">
      <div className="container">
        <div className="section">
          <p className="appic-publish-repeat-business-title is-size-2 is-uppercase is-size-4-mobile">Repeat Business 再来一遍</p>
          <p className="is-size-6-mobile is-font-ubuntu">
            This means “bring me another” in Chinese and often refers to a round of drinks. For us, we are using it here to emphasize one key metric for this industry, repeat business. We probably get more repeat business than any other mobile publisher in China. <Link to="/about/">Contact us to find out why.</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default RepeatBusiness
