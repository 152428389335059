import React from "react"
import("./friend-ship.scss")

const FriendShip = () => {
  return (
    <div className="appic-friend-ship-container">
      <div className="columns ship-item">
        <div className="column section-left section">
          <div className="columns is-multiline">
            <div className="is-size-2 column is-12 is-uppercase ship-title has-text-centered-mobile is-size-4-mobile is-font-bebaskai">
              We work with the Best
            </div>
            <div className="ship-desc column is-variable is-10-desktop is-size-7-mobile is-10-fullhd is-font-ubuntu">
              Our clients are among the most successful and talented out there.
              Together, they represent billions of downloads globally, and many
              lifetimes of entertainment happiness. It is our honor to be
              selected to extend their reach into China or wherever they may
              have wanted our help.
            </div>
          </div>
        </div>
        <div className="column is-narrow ship-right has-text-centered is-hidden-mobile">
          <figure className="image is-256x256 has-text-centered " style={{ height: 'auto', margin: '0 auto' }}>
            <img src={require("../../images/win.png")} alt="win" />
          </figure>
        </div>
      </div>
      <div className="columns ship-item">
        <div className="column is-narrow ship-right has-text-centered is-hidden-mobile">
          <figure className="image has-text-centered ">
            <img src={require("../../images/Cooperation.png")} alt="win" />
          </figure>
        </div>
        <div className="column section-left section has-background-white">
          <div className="columns is-multiline">
            <p className="is-size-2 column is-12 is-uppercase ship-title has-text-centered-mobile is-size-4-mobile  is-font-bebaskai">
              Built 100% on Trust
            </p>
            <p className="ship-desc column is-variable is-10-desktop is-size-7-mobile is-10-fullhd is-font-ubuntu">
              We put relationships first and have been helping people with free
              China advice for years. Our philosophy is that once trust and
              mutual understanding is in place, good business can follow. Come
              and get to know us and you will see what we mean. The best deals
              are built this way.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FriendShip
