import React from "react"
import { Link } from 'gatsby'
import ReactComment from "../ReactComment/ReactComment"
import("./footer.sass")

const Footer = () => {
  return (
    <footer className="appic-footer-container footer">
      <ReactComment text="footer" />
      <div className="container">
        <div className="columns is-mobile is-centered is-multiline">
          <div className="column is-6-mobile is-half foot-l is-flex-mobile">
            <div className="columns">
              <div className="column is-12-mobile is-6-tablet is-5-widescreen is-4-fullhd footer-logo-text is-flex-fullhd">
                <figure className="image is-128x128-fullhd">
                  <img
                    src={require("../../images/logo_text_black.png")}
                    alt="logo_text_black"
                  />
                </figure>
              </div>
              <div className="column is-hidden-mobile is-size-6-tablet is-size-6-widescreen is-5-widescreen is-5-fullhd is-size-5-fullhd is-flex-fullhd">
                <ul>
                  <li className="">
                    © 2016 - {new Date().getFullYear()}{" "}
                    <a href="https://www.appicplay.com">AppicPlay</a>
                  </li>
                  <li><a href="http://www.beian.miit.gov.cn/" target="_blank">京ICP备16067489号-1</a></li>
                  <li><Link  to="/privacy-policy/?lang=en" state={{status:true}}>Privacy Policy</Link> &nbsp; &nbsp; <Link to="/privacy-policy/?lang=zh" state={{status:false}}>隐私条款</Link></li>
                  <li><Link to="/terms-of-service/?lang=en" state={{status:true}}>Terms of Service</Link> &nbsp; &nbsp; <Link to="/terms-of-service/?lang=zh" state={{status:false}}>用户协议</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column is-half foot-r has-text-right is-inline-block">
            <a href="https://www.facebook.com/AppicPlay-1986321324923239/" rel="noopener noreferrer" target="_blank">
              <figure className="image is-64x64 is-inline-block" style={{marginRight: 10}}>
                <img width="64" height="64" src={require("../../images/facebook.png")} alt="icons-facebook" />
              </figure>
            </a>
            <a href="https://twitter.com/appicplay" rel="noopener noreferrer" target="_blank">
              <figure className="image is-64x64 is-inline-block">
                <img width="64" height="64" src={require("../../images/twitter.png")} alt="icons-twitter" />
              </figure>
            </a>
          </div>
          <div className="column is-hidden-tablet is-size-7-mobile footer-mobile-co">
            <div className="columns is-multiline">
              <div className="column">
              <Link to="/privacy-policy/?lang=en" state={{status:false}}>Privacy&nbsp;&nbsp;Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to="/privacy-policy/?lang=zh" state={{status:false}}>隐私条款</Link>
              </div>
              <div className="column">
                © 2016-2019 AppicPlay 京ICP备16067489号-1
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
