import React from "react"
import Swiper from "react-id-swiper"
import("./top-games.scss")
import("./loading.sass")

const TopGames = ({ appicplay }) => {
  // const { appicplay: { home: { topGames }, siteProps } } = appicplay;
  const { appicplay: data } = appicplay
  const { home, siteProps } = data;
  let lists = home && home.topGames.pics;
  let BaseOSSUrl = ``;
  let PreViewNumPic = 4;
  let PreViewNumMobile = 1;
  if (typeof home !== `undefined`) {
    if (typeof home.topGames !== `undefined`) {
      if (home.topGames.show && home.topGames.show.pc !== `undefined`) {
        PreViewNumPic = home.topGames.show.pc;
      }
      if (home.topGames.show && home.topGames.show.mobile !== `undefined`) {
        PreViewNumMobile = home.topGames.show.mobile;
      }
    }
  }

  if (typeof siteProps !== `undefined`) {
    BaseOSSUrl = siteProps.url + siteProps.folder
  }

  const params = {
    slidesPerView: PreViewNumPic,
    spaceBetween: 0,
    grabCursor: true,
    loop: true,
    lazy: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    breakpoints: {
      1920: {
        slidesPerView: 6,
        spaceBetween: 0,
      },
      1024: {
        slidesPerView: PreViewNumPic,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: PreViewNumPic,
        spaceBetween: 0,
      },
      640: {
        slidesPerView: PreViewNumMobile,
        spaceBetween: 0,
      },
      320: {
        slidesPerView: PreViewNumMobile,
        spaceBetween: 0,
      },
    },
  }
  return (
    <div className="appic-top-games is-size-3" name="#top-games">
      <Swiper {...params}>
        {lists && lists.map((item, i) => {
          return (
            <div
              key={i}
              className={
                i >= 2
                  ? "appic-main-top-games-rect2"
                  : "appic-main-top-games-rect"
              }
            >
              <img className="swiper-lazy" data-src={BaseOSSUrl + item.imgUrl} alt={item.title} />
              <div className="indicator swiper-lazy-preloader ">
                <svg width="32px" height="24px">
                  <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
                  <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
                </svg>
              </div>
              <div className="bottom-cover is-font-bebaskai is-size-1">
                <div className="b-cover"></div>
              </div>
            </div>
          )
        })}
      </Swiper>
    </div>
  )
}

export default TopGames
