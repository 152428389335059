import React from "react"
import("./adress.scss")

const Address = ({ appicplay }) => {
  const { appicplay: data } = appicplay
  const { home } = data;
  let leftAddress = {};
  let rightAddress = {};
  if (typeof home !== `undefined`) {
    if (typeof home.address !== `undefined`) {
      if (typeof home.address.leftAddress !== 'undefined') {
        leftAddress = home.address.leftAddress;
      }

      if (typeof home.address.rightAddress !== 'undefined') {
        rightAddress = home.address.rightAddress;
      }
    }
  }
  return (
    <div className="appic-address is-hidden-mobile">
      <div className="container section">
        <div className="columns is-multiline">
          <div className="column is-full has-text-centered is-size-1 is-uppercase is-font-bebaskai has-text-black-ter">
            Meet Us at these Coming Trade Shows
          </div>
          <div className="column">
            <div className="columns link-address">
              <div className="column is-half is-size-5">
                <div className="columns">
                  <div className="column is-offset-5 is-half is-font-ubuntu">
                    <div className="is-size-2 is-uppercase is-font-bebaskai has-text-white">
                      { leftAddress.title ? leftAddress.title : 'Not Title' }
                    </div>
                    <p>{ leftAddress.date ? leftAddress.date : 'Not Date' }</p>
                    <p>{ leftAddress.address ? leftAddress.address : 'Not Address' }</p>
                    <p>
                      Meetings:{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ leftAddress.meetings ? leftAddress.meetings : '' }
                      >
                        { leftAddress.meetings ? leftAddress.meetings : 'Not Meetings' }
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="column is-size-5">
                <div className="columns">
                  <div className="column is-offset-2 is-font-ubuntu">
                    <div className="is-size-2 is-uppercase is-font-bebaskai has-text-white">
                      { rightAddress.title ? rightAddress.title : 'Not Title' }
                    </div>
                    <p>{ rightAddress.date ? rightAddress.date : 'Not Date' }</p>
                    <p>{ rightAddress.address ? rightAddress.address : 'Not Address' }</p>
                    <p>
                      Meetings:{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ rightAddress.meetings ? rightAddress.meetings : '' }
                      >
                        { rightAddress.meetings ? rightAddress.meetings : 'Not Meetings' }
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Address
