import React from 'react'
import ReactComment from '../ReactComment/ReactComment'

import LogoDesc from '../LogoDesc/LogoDesc'
import OurTeam from '../OurTeam/OurTeam'
import GetInTouch from '../GetInTouch/GetInTouch'
import Recruiting from '../Recruiting/Recruiting'
import OurAddress from '../OurAddress/OurAddress'

const AboutComponents = ({ appicplay }) => {
  return (
    <div id='appic-AboutComponents' className="appic-about">
      <ReactComment text={"logo-desc"} />
      <LogoDesc />
      <ReactComment text={"our-team"} />
      <OurTeam appicplay={appicplay}/>
      <ReactComment text={"get-in-touch"} />
      <GetInTouch />
      <ReactComment text={"recruiting"} />
      <Recruiting />
      <ReactComment text={"our-address"} />
      <OurAddress />
    </div>
  )
}

export default AboutComponents
