import React, { useState } from 'react'
import('./get-in-touch.sass')


const FormContactUs = () => {
  return (
    <div className="appic-about-get-in-touch-form">
      <form>
        <div className="control">
          <input className="input is-large is-warning" type="text" placeholder="Name" />
        </div>
        <div className="control">
          <input className="input is-large is-warning" type="email" placeholder="Email" />
        </div>
        <div className="control">
          <textarea className="textarea is-large is-warning" placeholder="Message"></textarea>
        </div>
        <div className="has-text-centered is-font-ubuntu">
          <div className="form-submit-btns is-size-4">
            Send
          </div>
        </div>
      </form>
    </div>
  )
}

const FormGames = () => {
  return (
    <div className="appic-about-get-in-touch-form">
      <form>
        <div className="control">
          <input className="input is-large is-warning" type="text" placeholder="Games Name" />
        </div>
        <div className="control">
          <input className="input is-large is-warning" type="email" placeholder="Email" />
        </div>
        <div className="control">
          <textarea className="textarea is-large is-warning" placeholder="Message"></textarea>
        </div>
        <div className="has-text-centered is-font-ubuntu">
          <div className="form-submit-btns is-size-4">
            Send
          </div>
        </div>
      </form>
    </div>
  )
}


const GetInTouch = () => {
  const tabs = ['Contact Us', 'Submit Game']
  const [idx, setIdx] = useState(0)

  const clickAction = (i, e) => {
    e.preventDefault();
    setIdx(i);
  }


  return (
    <div id='appic-GetInTouch' className="appic-about-get-in-touch" name="#get-in-touch">
      <div className="container">
        <div className="section has-text-centered">
          <div className="appic-about-get-in-touch-title is-size-1 is-font-bebaskai">
            Get In Touch
          </div>
          <div className="appic-about-get-in-touch-desc is-font-ubuntu is-size-5 is-size-6-mobile is-have-padding-tb-lr0">
            Get your Game Review Started in as Little as 60 seconds! Or just contact us for general information.
            Use the form below or email us at <a target="_blank" className="link-active is-white-text" rel="noopener noreferrer" href="mailto:info@appicplay.com">info@appicplay.com</a>
          </div>
          <div className="appic-about-get-in-touch-form">
            <div className="appic-tabs tabs is-boxed is-toggle is-large is-font-bebaskai">
              <ul>
                {
                  tabs.map((item, i) => (
                      <li key={item} className={idx === i ? 'is-active is-size-3' : 'is-size-3'}>
                        <a href="#!" rel="noopener noreferrer" onClick={clickAction.bind(this, i)}>
                          <span>{item}</span>
                        </a>
                      </li>
                    )
                  )
                }
              </ul>
            </div>
            <div className="appic-tabs-body has-text-left section">
              {
                idx === 0 ?
                  <FormContactUs />
                :
                idx === 1 ?
                  <FormGames />
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetInTouch
