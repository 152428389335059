import React from "react"
import("./games-area.scss")

const GamesArea = () => {
  const countrys = {
    left: [
      "Argentina",
      "Australia",
      "Belarus",
      "Brazil",
      "Chile",
      "China",
      "Croatia",
      "Finland",
      "France",
      "Germany",
      "Hungary",
    ],
    right: [
      "Malaysia",
      "Netherlands",
      "Poland",
      "Russia",
      "Serbia",
      "South Africa",
      "Spain",
      "Sweden",
      "U.K.",
      "Ukraine",
      "USA",
    ],
  }

  return (
    <div className="appic-games-area" id="appic-map">
      <div className="appic-games-area-container container">
        <div className="appic-games-area-title is-size-1 is-font-bebaskai is-size-4-mobile has-text-centered-desktop has-text-centered-widescreen has-text-centered-mobile is-uppercase">
          Our Games are made Everywhere
        </div>
        <div className="appic-games-area-content is-size-6">
          <div className="appic-left-country has-text-left">
            <ul>
              {countrys.left.map((item, i) => {
                return (
                  <li key={i + item} className="is-uppercase is-size-7-mobile">
                    {item}
                  </li>
                )
              })}
            </ul>
          </div>
          <div
            className="appic-center-map has-text-centered"
            style={{ marginTop: "25px" }}
          >
            <img src={require("../../images/map.png")} alt="appic-area-map" />
          </div>
          <div className="appic-right-country has-text-right is-size-7-mobile">
            <ul>
              {countrys.right.map((item, i) => {
                return (
                  <li key={i + item} className="is-uppercase is-size-7-mobile">
                    {item}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="appic-games-area-b-desc is-font-ubuntu is-size-7-mobile">
          Those that attend the trade shows maybe have seen a lot of us in the
          last year. We literally roam the entire globe looking for great games
          where we can really add value as a publisher.
        </div>
      </div>
    </div>
  )
}

export default GamesArea
