import React from 'react'
import { navigate } from '@reach/router'
import('./case.sass')

const Cases = () => {

  const toContentUs = () => {
    if (window.location.pathname !== "/about/") {
      navigate("/about/")
      setTimeout(() => {
        const rectTop = document.querySelector('div[name="#get-in-touch"]')
          .offsetTop
        window.scrollTo({
          top: rectTop,
          behavior: "smooth",
        })
      }, 200)
    } else {
      const rectTop = document.querySelector('div[name="#get-in-touch"]').offsetTop
      window.scrollTo({
        top: rectTop,
        behavior: "smooth",
      })
    }
  }


  return (
    <div id="case" className="appic-publish-case">
      <div className="container">
        <div className="section has-text-centered is-size-4 is-size-7-mobile">
          <p>Every successful publisher needs to a certain skill set and experience level to deliver amazing results. Here are a few examples of our capabilities in motion.</p>
        </div>
        <div className="appic-publish-case-box section is-paddingless">
          <p className="appic-publish-case-box-item-top  is-size-2 has-text-white">AS EASY AS...</p>
          <div className="appic-publish-case-box-item ">
            <div className="columns">
              <div className="column appic-publish-case-box-item-num is-hidden-mobile">
                <img src={require('../../images/num-1.png')} alt="num-1"/>
              </div>
              <div className="column is-11 appic-publish-case-box-item-content">
                <div className="appic-publish-case-box-item-title is-size-2 is-size-5-mobile is-uppercase">
                  UA Power Ups
                </div>
                <div className="appic-publish-case-box-item-desc is-size-7-mobile">
                  Even Older Games of Quality can see Fresh Results.
                  Paid marketing or UA is the norm these days. Faily Brakes, by Spunge Games, was a global franchise hit game that is now more than 3 years old. With the necessary metrics achieved for the fully localized version, we started paid marketing in December 2018. Within 30 days, our monthly UA spend for that title was already $200k and at a highly worthwhile day 14 ROAS. This brought fresh financial success to the game and retired the MG very early in the contract cycle.
                </div>
                <div className="appic-publish-case-box-item-desc is-size-7-mobile  is-size-5 comments">
                  "If your game has the right metrics, we have both the experience and the resources to support its paid marketing at scale."
                </div>
              </div>
            </div>
          </div>
          <div className="appic-publish-case-box-item">
            <div className="columns">
              <div className="column is-1 appic-publish-case-box-item-num is-hidden-mobile">
                <img src={require('../../images/num-2.png')} alt="num-1"/>
              </div>
              <div className="column appic-publish-case-box-item-content">
                <div className="appic-publish-case-box-item-title is-size-2 is-uppercase is-size-5-mobile">
                  Show Me the Money!
                </div>
                <div className="appic-publish-case-box-item-desc is-size-7-mobile">
                Ads are now a mainstay for monetizing casual, free-to-play games globally, and China is no exception. In fact, the rates in China for ads have been among the most competitive in the world, but most Western companies are unaware of that. Like many publishers in China, we have our own ad tech to accomplish this. Unlike most other publishers here though, our robust proprietary mediation and ad net power about 50 million DAU of other peoples’ apps and games, such as for Hipster Whale and PicsArt.
                </div>
                <div className="appic-publish-case-box-item-desc is-size-7-mobile  is-size-5 comments">
                "Publish with us and get this world class monetization supporting your game in China today. Or if you have lots of iOS traffic already, contact us  to find out about making more ad revenue now! <b onClick={toContentUs} className="link-active has-text-weight-normal">reach out to us now!"</b>
                </div>
              </div>
            </div>
          </div>
          <div className="appic-publish-case-box-item ">
            <div className="columns">
              <div className="column is-1 appic-publish-case-box-item-num is-hidden-mobile">
                <img src={require('../../images/num-3.png')} alt="num-3"/>
              </div>
              <div className="column appic-publish-case-box-item-content">
                <div className="appic-publish-case-box-item-title is-size-5-mobile is-size-2 is-uppercase">
                  From 0 to 100+ million in 50 Flat
                </div>
                <div className="appic-publish-case-box-item-desc is-size-7-mobile">
                  Enormous scale is what most publishers strive for, but very few actually achieve. Fortunately, this team was central to a massively popular and successful F2P game in China. It saw hundreds of millions of downloads and financial success for several years. Our current core team of 50 people are mostly made up from the same team that achieved this big hit game. We are standing by ready for the next big opportunity...maybe it is with your game.
                </div>
                <div className="appic-publish-case-box-item-desc is-size-7-mobile  is-size-5 comments">
                  "We know casual games, the China market, at least 100 of the most important marketing channels, have the UA experience and resources necessary for scale, and have our own market proven ad monetization. Put all of this to work for your game - <b onClick={toContentUs} className="link-active has-text-weight-normal">Contact us today!"</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cases
