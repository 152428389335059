import React from 'react'
import('./recruiting.sass')

const Recruiting = () => {
  return (
    <div id='appic-Recruiting' className="appic-about-recruiting">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-half">
              <div className="appic-about-recruiting-title is-uppercase is-size-1 is-font-bebaskai">
                Recruiting
              </div>
              <div className="appic-about-recruiting-list is-font-ubuntu">
                <div>Have you got what it takes? We are looking for great people for the following positions within our global team:</div>
                <ul className="appic-about-recruiting-list-box is-size-7-mobile">
                  <li>Server Side Senior Developer [Beijing, China]</li>
                  <li>UI/UX Designer [Beijing, China]</li>
                  <li>Junior Developer Intern (Western) [Beijing, China]</li>
                  <li>Overseas BD Director [North America or Europe]</li>
                </ul>
              </div>
              <div className="appic-about-recruiting-link is-font-ubuntu">
                Send applications to <a className="link-active" href="mailto:hr@appicplay.com" target="_blank" rel="noopener noreferrer">hr@appicplay.com</a>
              </div>
            </div>
            <div className="column is-half">
              <figure className="image is-360x360 img-center">
                <img src={require('../../images/recruiting.png')} alt="logo"/>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recruiting
