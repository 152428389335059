import React from 'react'
import { navigate } from "@reach/router"
import('./TermsContent.sass')


const PrivacyContent = () => {

    const toContentUs = () => {
      if (window.location.pathname !== "/about/") {
        navigate("/about/")
        setTimeout(() => {
          const rectTop = document.querySelector('div[name="#get-in-touch"]')
            .offsetTop
          window.scrollTo({
            top: rectTop,
            behavior: "smooth",
          })
        }, 200)
      } else {
        const rectTop = document.querySelector('div[name="#get-in-touch"]').offsetTop
        window.scrollTo({
          top: rectTop,
          behavior: "smooth",
        })
      }
    }
  
  
    return (
      <div id='appic-PrivacyContent' className="appic-privacy-policy-content">
        <div className="container is-over-header">
          <div className="section is-paddingless-l is-paddingless-r">
            <div className="is-size-1 is-font-bebaskai appic-privacy-policy-content-title">AppicPlay Terms of Service</div>
            <div className="appic-privacy-policy-content-desc is-font-ubuntu">
              Last Updated: Janurary 9, 2020<br />
              Please read these Beijing AppicPlay, Ltd (“AppicPlay”, “our”, “we” or “us”) Terms of Service (the “Terms”) and our Privacy Policy available at <a href='http://www.appicplay.com/blank'>http://www.appicplay.com/blank</a> carefully because they govern your use of our website <a href='http://www.appicplay.com'>http://www.appicplay.com</a>  (the “Site”) and services accessible via our mobile applications (each an “App”, collectively “Apps”). To make these Terms easier to read, our Site and the Apps are collectively called the “Services.” 
              <p className='appic-privacy-policy-content-desc-bt'>1. Agreement to Terms</p>
              <p>By using our Services, you agree to be bound by these Terms and by our Privacy Policy. If you don’t agree to these Terms and our Privacy Policy, do not use the Services. </p><br/>
              <p>IMPORTANT: PLEASE REVIEW THE ARBITRATION AGREEMENT SET FORTH BELOW CAREFULLY, AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES WITH US ON AN INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.</p>
              <p className='appic-privacy-policy-content-desc-bt'>2. Changes to Terms or Services </p>
              <p>We may modify the Terms at any time, in our sole discretion. If we do so, we’ll let you know either by posting the modified Terms on the Site or through other communications via the Services. It’s important that you review the Terms whenever we modify them because if you continue to use the Services after we have posted modified Terms on the Site or via the Services, you are indicating to us that you agree to be bound by the modified Terms. If you don’t agree to be bound by the modified Terms, then you may not use the Site or the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion. </p>
              <p className='appic-privacy-policy-content-desc-bt'>3. Who May Use the Services</p>
              <p className="p-list-item">3.1. Eligibility – You may use the Services only if you are 16 years or older and are not barred from using the Services under applicable law. <br/>If you are under the age of 18, or under the age of majority where you live, you represent that your legal guardian has reviewed and agreed to these Terms. </p>
              <p className="p-list-item">3.2. Registration and Your Information – It’s important that you provide us with accurate, complete and up-to-date information for your account and you agree to update such information, as needed, to keep it accurate, complete and up-to-date. If you don’t, we might have to suspend or terminate your account. You agree that you won’t disclose your account password to anyone and you’ll notify us immediately of any unauthorized use of your account. You’re responsible for all activities that occur under your account, whether or not you know about them. </p>
              <p className='appic-privacy-policy-content-desc-bt'>4. Privacy Policy </p>
              <p>Please refer to our Privacy Policy available at <a href='http://www.appicplay.com/blank'>http://www.appicplay.com/blank</a>  for information on how we collect, use and disclose information from you, your computer and your mobile device. You understand that through your use of our Services you acknowledge the collection, use and sharing of this information as described in our Privacy Policy. If you don’t agree with the Privacy Policy, then you must stop using our Services. </p>
              <p className='appic-privacy-policy-content-desc-bt'>5. Content and Content Rights </p>
              <p>For purposes of these Terms: (i) “Content” means text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are posted, generated, provided or otherwise made available through the Services; and (ii) “User Content” means any Content that account holders (including you) provide to be made available through the Services. Content includes without limitation User Content. </p>
              <p className="p-list-item">5.1. Content Ownership, Responsibility and Removal</p>
              <p>We do not claim any ownership rights in any User Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit your User Content. Subject to the foregoing, we and our licensors exclusively own all right, title and interest in and to the Services and Content, including all associated intellectual property rights. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Content.</p>
              <p className="p-list-item">5.2. Rights in User Content Granted by You. By making any User Content available through Services you hereby grant to us a non-exclusive, transferable, sublicenseable, worldwide, royalty-free license to use, copy, modify, create derivative works based upon, distribute, publicly display, publicly perform and distribute your User Content in connection with operating and providing the Services and Content to you and to other account holders. You are solely responsible for all your User Content. You represent and warrant that you own all your User Content or you have all rights that are necessary to grant us the license rights in your User Content under these Terms. You also represent and warrant that neither your User Content, nor your use and provision of your User Content to be made available through the Services, nor any use of your User Content by us on or through the Services will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation. </p>
              <p>We are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any of your User Content. </p>
              <p className="p-list-item">5.3. Rights in Content Granted by Us. Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to download, view, copy, display and print the Content solely in connection with your permitted use of the Services and solely for your personal and non-commercial purposes.  </p>
              



              <p className='appic-privacy-policy-content-desc-bt'>6. Rights and Terms for Apps </p>
              <p className="p-list-item">6.1. Rights in App Granted by Us. Subject to your compliance with these Terms, we grant you a limited non-exclusive, non-transferable, non-sublicenseable license to download and install a copy of the App on a mobile device that you own or control and to run such copy of the App solely for your own personal non-commercial purposes. You may not copy the App, except for making a reasonable number of copies for backup or archival purposes. Except as expressly permitted in these Terms, you may not: (i) copy, modify or create derivative works based on the App; (ii) distribute, transfer, sublicense, lease, lend or rent the App to any third party; (iii) reverse engineer, decompile or disassemble the App; or (iv) make the functionality of the App available to multiple users through any means. We reserve all rights in and to the App not expressly granted to you under these Terms. </p>

              <p className='appic-privacy-policy-content-desc-bt'>7. General Prohibitions and Our Enforcement Rights </p>
              <p>You agree not to do any of the following: </p>
              <p className="p-list-item">●	Post, upload, publish, submit or transmit any Content that: (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or substances.</p>
              <p className="p-list-item">●	Use, display, mirror or frame the Services or any individual element within the Services, our name, any of our trademarks, logo or other proprietary information, or the layout and design of any page or form contained on a page, without our express written consent;</p>
              <p className="p-list-item">●	Access, tamper with, or use non-public areas of the Services, our computer systems, or the technical delivery systems of our providers;</p>
              <p className="p-list-item">●	Attempt to probe, scan or test the vulnerability of any of our systems or networks or breach any security or authentication measures;</p>
              <p className="p-list-item">●	Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by us or any of our providers or any other third party (including another user) to protect the Services or Content;</p>
              <p className="p-list-item">●	Attempt to access or search the Services or Content or download Content from the Services through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by us or other generally available third-party web browsers;</p>
              <p className="p-list-item">●	Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation;</p>
              <p className="p-list-item">●	Use any meta tags or other hidden text or metadata utilizing our trademark, logo URL or product name without our express written consent;</p>
              <p className="p-list-item">●	Use the Services or Content, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms;</p>
              <p className="p-list-item">●	Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Services or Content;</p>
              <p className="p-list-item">●	Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;</p>
              <p className="p-list-item">●	Collect or store any personally identifiable information from the Services from other users of the Services without their express permission;</p>
              <p className="p-list-item">●	Impersonate or misrepresent your affiliation with any person or entity</p>
              <p className="p-list-item">●	Violate any applicable law or regulation; or</p>
              <p className="p-list-item">●	Encourage or enable any other individual to do any of the foregoing.</p>
              <p>Although we’re not obligated to monitor access to or use of the Services or Content or to review, display or edit any Content, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms, and to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to remove or disable access to any Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider any Content to be objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law. </p>
              <p className='appic-privacy-policy-content-desc-bt'>8. DMCA/Copyright Policy </p>
              <p>AppicPlay respects copyright law and expects its users to do the same. It is our policy to terminate in appropriate circumstances account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders. </p>
              <p>The Digital Millennium Copyright Act provides recourse to copyright owners who believe that their rights under the Copyright Act have been infringed by acts of third parties over the Internet. If you believe that your copyrighted work has been copied without your authorization and is available on or in the Services in a way that may constitute copyright infringement, you may provide notice of your claim to our Designated Agent listed below. For your notice to be effective, it must include the following information: </p>
              <p className="p-list-item">(i) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; </p>
              <p className="p-list-item">(ii) A description of the copyrighted work that you claim has been infringed upon; </p>
              <p className="p-list-item">(iii) A description of where the material that you claim is infringing is located within the Services;</p>
              <p className="p-list-item">(iv) Information reasonably sufficient to permit us to contact you, such as address, telephone number, and, if available, an email address at which you may be contacted; </p>
              <p className="p-list-item">(v) A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and </p>
              <p className="p-list-item">(vi) A statement that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
              <p>Our Designated Agent is:</p>
              <p>Beijing AppicPlay Ltd.</p>
              <p>Suite 401-403, Building 1, Aviation Science and Technology Building, Chaoyang District, Beijing, China</p>
              <a href='http://www.appicplay.com'>http://www.appicplay.com</a>
              <p className='appic-privacy-policy-content-desc-bt'>9. Payment Terms</p>
              <p className="p-list-item">9.1. In-App purchases</p>
              <p>AppicPlay may license to you certain virtual goods to be used within the Apps and which you may purchase with real cash or which you may earn or redeem via gameplay (“Virtual Items”). When you obtain such Virtual Items from AppicPlay or its authorized designee, you receive a limited, personal, non-transferable, non-sublicensable and revocable license to use such Virtual Items within the applicable App and solely for non-commercial use.</p>
              <p>AppicPlay may manage, control, modify or eliminate Virtual Items at any time, with or without notice. Without limiting the foregoing, AppicPlay reserves the right at any time to impose additional terms or conditions on your use of Virtual Items. Such additional terms and conditions will be effective immediately and incorporated into this Agreement. Your continued use of the App will be deemed acceptance thereof. If we need to contact you about your Virtual Items or your account, you consent to receive the notices by email. You acknowledge and agree that any such notices that we send to you electronically will satisfy any legal communication requirements.</p>
              <p>The transfer of Virtual Items is prohibited, except where expressly authorized in the Apps, if any.</p>
              <p>Virtual Items have no monetary value and are not currency or property. Virtual Items do not have an equivalent value in real cash and do not act as a substitute for real world money. Virtual Items cannot be sold, traded, transferred, or exchanged for cash. Virtual Items may only be redeemed for certain gameplay entitlements that may be made available within the App from time to time. Neither AppicPlay nor any other person or entity has any obligation to exchange Virtual Items for anything of value. AppicPlay has no liability for hacking or loss of your Virtual Items.</p>
              <p>Price and availability of Virtual Items are subject to change without notice.</p>
              <p>Unless otherwise required by law, all purchases and redemptions of Virtual Items made through the App or Services are final and non-refundable, and you are not entitled to a refund for any unused Virtual Items. Once you redeem a Virtual Item for a gameplay entitlement within the App or Services, such gameplay entitlement is not returnable, exchangeable, or refundable. You acknowledge and consent that the provision of Virtual Items for use in connection with the App or Services is a process that commences immediately upon purchase and you forfeit your right of withdrawal once the performance has started.</p>
              <p>Unless otherwise required by law, you agree that AppicPlay is not required to provide a refund for Virtual Items for any reason, and that you will not receive money or other compensation for unused Virtual Items, whether your loss of license under this Agreement was voluntary or involuntary.</p>
              <p>If you request your personal data to be erased as specified in AppicPlay’s Privacy Policy, you will permanently and without a right to a refund lose all of your Virtual Items as Lion can no longer associate such Virtual Items with you.</p>
              <p><strong>EXCEPT AS OTHERWISE PROVIDED HEREIN, YOU ACKNOWLEDGE AND AGREE THAT APPICPLAY IS NOT REQUIRED TO PROVIDE A REFUND TO YOU FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR ANY UNUSED VIRTUAL ITEMS WHEN YOU DELETE YOUR ACCOUNT, YOUR ACCOUNT IS TERMINATED, OR WHEN YOU CEASE TO USE THE APP OR SERVICES.</strong></p>
              <p className="p-list-item">9.2. Subscriptions</p>
              <p>Some of AppicPlay’s Apps may offer subscription-based Services. If you purchase a subscription, then by clicking the purchase button you are requesting that we begin supplying the subscription Services immediately and you are entering into a monthly subscription contract with AppicPlay. You are also authorizing a charge to you on a recurring basis of a monthly subscription fee at the rate quoted at the time of purchase.  By purchasing a subscription, we will automatically charge on a recurring basis the payment method associated with your account. For subscription Services purchased in a AppicPlay App played on a platform such as Apple or Google, the applicable platform will charge you for the subscription fee and the platform’s payment terms will apply. Please review the appropriate platform’s payment terms for additional information.</p>
              <p>Trial subscriptions are offered free of charge for a certain period of time from activation specified in the relevant trial offer in the App. If you do not cancel the subscription within such trial period, the trial subscription will automatically convert into a paid subscription for the fees and for the subscription period set out in the App at the time of the activation of the trial period. You may cancel a subscription during its free trial period using the subscription setting of your account. Any paid subscription you activate begins immediately from the activation of such paid subscription and not after the expiration of any trial period. Any unused trial period will therefore be forfeited if a subscription is activated prior to the expiration of such trial period.</p>
              <p>Your subscription will automatically renew each subscription period unless and until you terminate your subscription or we terminate it. You must cancel your subscription before it renews, otherwise payment of the subscription fees for the next period will be taken automatically via your chosen payment method. You are responsible for the timely payment of all fees and for providing AppicPlay with valid credit card or payment account details for payment of all fees. In case the subscription fee cannot be taken from your account due to absence of monetary funds, invalidity of credit card or for any other reason, your subscription will be automatically cancelled.</p>
              <p>Subscription rates are based on an amount in U.S. Dollars. If you are using a local currency, the actual amount may fluctuate based on currency exchange rates without notice to you. The charges will be applied to the payment instrument or method you provide when you start your subscription (or to a different payment instrument or method, if you change your account information). Please note that prices and charges are subject to change. If we make a change to the monthly subscription rate in U.S. Dollars, we will notify you of such change in advance.</p>
              <p>Once you have purchased a subscription, you cannot cancel your subscription for the current subscription period as that is activated as soon as you purchase a subscription. However, you may cancel your subscription for the next subscription period as follows: you can manage and cancel your subscription at any time in your “Account Settings” of your device. For iOS subscriptions, please see Apple’s support page <a href='https://support.apple.com/en-gb/HT202039'>https://support.apple.com/en-gb/HT202039</a> . For Google Play subscriptions, please see Google Play’s support page <a href='https://support.google.com/googleplay/answer/7018481?hl=en-GB&amp;co=GENIE.Platform%3DAndroid'>https://support.google.com/googleplay/answer/7018481?hl=en-GB&amp;co=GENIE.Platform%3DAndroid</a></p>
              <p>Except where required by applicable law, paid subscription fees are non-refundable.</p>
              <p>AppicPlay in its sole discretion and at any time may modify the subscription fee. Any subscription fee change will become effective at the end of the then-current subscription period. You will be provided reasonable prior notice of any change in subscription fee. If you do not take action to agree to the increase in subscription fee, your subscription shall expire at the end of the then-current subscription period.</p>
              <p className='appic-privacy-policy-content-desc-bt'>10. Links to Third Party Websites or Resources </p>
              <p>The Services and Apps may contain links to third-party websites or resources. We provide these links only as a convenience and are not responsible for the content, products or services on or available from those websites or resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any third-party websites or resources. </p>
              <p className='appic-privacy-policy-content-desc-bt'>11. Apple </p>
              <p>If you use the Services on an Apple device, then you agree and acknowledge that: </p>
              <p className="p-list-item">●	Apple, Inc. bears no duties or obligations to you under the Terms, including, but not limited to, any obligation to furnish you with maintenance and support with respect to the Services;</p>
              <p className="p-list-item">●	You will have no claims, and you waive any and all rights and causes of action against Apple with respect to the Services or the Terms, including, but not limited to claims related to maintenance and support, intellectual property infringement, liability, consumer protection, or regulatory or legal conformance; and</p>
              <p className="p-list-item">●	Apple and Apple’s subsidiaries are third party beneficiaries of the Terms. Upon your acceptance of the Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary thereof.</p>
              <p className='appic-privacy-policy-content-desc-bt'>12. Termination </p>
              <p>We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice to you. Upon any termination, discontinuation or cancellation of Services or your account, all provisions of these Terms which by their nature should survive will survive, including, without limitation, ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution provisions. </p>
              <p className='appic-privacy-policy-content-desc-bt'>13. Warranty Disclaimers </p>
              <p>THE SERVICES AND CONTENT ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. </p>
              <p> We make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any Content. </p>
              <p className='appic-privacy-policy-content-desc-bt'>14. Indemnity </p>
              <p>You will indemnify us and hold us harmless including our affiliated entities and their and its officers, directors, employee and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (i) your access to or use of the Services or Content, (ii) your User Content, or (iii) your violation of these Terms. </p>
              <p className='appic-privacy-policy-content-desc-bt'>15. Limitation of Liability </p>
              <p>NEITHER WE NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES OR CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT WILL OUR TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR CONTENT EXCEED THE AMOUNTS YOU HAVE PAID TO US FOR USE OF THE SERVICES OR CONTENT, IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO US, AS APPLICABLE. </p>
              <p>THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN US AND YOU. </p>
              <p className='appic-privacy-policy-content-desc-bt'>16. Time Limitation on Claims </p>
              <p>You agree that any claim you may have arising out of or relating to your relationship with us must be filed within one year after such claim arose; otherwise your claim is permanently barred. </p>
              <p className='appic-privacy-policy-content-desc-bt'>17. Dispute Resolution </p>
              <p>If you are having a problem with a Service, many issues can be resolved on one of our forums. You may find resolution by clicking on the “Support” link on that App. Before bringing a formal legal case, first contact our support team via the App to address your issue. Most disputes can be resolved that way. </p>
              <p className='appic-privacy-policy-content-desc-bt'>18. General Terms </p>
              <p>These Terms constitute the entire and exclusive understanding and agreement between us and you regarding the Services and Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between us and you regarding the Services and Content. If for any reason a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. </p>
              <p>You may not assign or transfer these Terms, by operation of law or otherwise, without our prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null. We may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns. </p>
              <p>Any notices or other communications provided by us under these Terms, including those regarding modifications to these Terms, will be given: (i) via email; or (ii) by posting to the Services. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted. </p>
              <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by our duly authorized representative. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise. </p>
              <p className='appic-privacy-policy-content-desc-bt'>19. Contact Information </p>
              <p>If you have any questions about these Terms or the Services, please contact us at <a href='http://www.appicplay.com'>http://www.appicplay.com</a>  or the address set forth below. </p>
              <p>Beijing AppicPlay Ltd.</p>
              <p>Suite 401-403, Building 1, Aviation Science and Technology Building, Chaoyang District, Beijing, China</p>
              {/* <p className="section is-paddingless-lr">If you do not agree to this Privacy Policy or have any doubts, please contact us mailing to <b onClick={toContentUs} className="link-active has-text-weight-normal">contact@appicplay.com.</b></p>
              <p>What Information Do We Collect?</p>
              <p>Information You Give To Us</p>
              <p className="section is-paddingless-lr is-paddingless-b">We receive and store any information you give to us, such as when you:</p>
              <p className="p-list-item">• download, update and play our Games or are involved in our special events (for example, offline campaign);</p>
              <p className="p-list-item">• enter information in our Services;</p>
              <p className="p-list-item">• make an in-app purchase;</p>
              <p className="p-list-item">• interact with us via social media such as Wechat, Weibo, QQ, etc.;</p>
              <p className="p-list-item">• respond to our customer surveys;</p>
              <p className="p-list-item">• subscribe to our newsletters or other information feeds;</p>
              <p className="p-list-item">• request technical support, make a compliment or complaint, or otherwise contact us directly;</p>
              <p className="p-list-item">• give us your information in any other forms.</p> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  export default PrivacyContent
  