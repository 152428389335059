import React from 'react'
import('./our-address.sass')

const OurAddress = () => {
  return (
    <div id='appic-OurAddress' className="appic-about-our-address">
      <div className="container">
        <div className="section">
          <div className="appic-about-our-address-title has-text-centered is-size-2 is-font-bebaskai">
            Our Address
          </div>
          <div className="appic-about-our-address-desc has-text-centered is-size-5 is-font-ubuntu">
            Suite 401-403, Building 1, Aviation Science and Technology Building, Chaoyang District, Beijing, China
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurAddress
