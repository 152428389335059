import React from 'react'
import ReactComment from '../ReactComment/ReactComment'

import HeadInfo from '../HeadInfo/HeadInfo'
import Case from '../Cases/Cases'
import RepeatBusiness from '../RepeatBusiness/RepeatBusiness'
import Product from '../Product/Product'

const PublishComponents = (props) => {
  return (
    <div className="appic-publish" style={{overflow: 'hidden'}}>
      <ReactComment text={'publish-head'} />
      <HeadInfo />
      <ReactComment text={'publish-case'} />
      <Case />
      <ReactComment text={'publish-repeat-business'} />
      <RepeatBusiness />
      <ReactComment text={'publish-product'} />
      <Product />
    </div>
  )
}

export default PublishComponents
