
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as utils from '../utils/raf'
import Lazyload from "react-lazyload"
import LazyLoadSvg from "../LazyLoad/LazyLoadSvg"
import('./marquee.scss')

utils.raf();

//当前执行时间
var nowTime = 0;
//记录每次动画执行结束的时间
var lastTime = Date.now();
//我们自己定义的动画时间差值
var diffTime = 150;

export default class Marquee extends Component {
  static defaultProps = {
    direction: 'landscape',
    verticalItemHeight: '72px'
  }

  constructor(props) {
    super(props);

    this.timerMarquee = null;
    this.domMi = null;
    this.domMw = null;
    if(typeof document !== 'undefined') {this.docW = document.body.scrollWidth}

    this.state = {};
  }

  initMarquee = () => {
    this.stopMarquee();
    this.runMarquee();
  }

  //横向滚动
  landscapeMarquee = () => {
    // console.log(1243)
    if ((this.domMw.scrollLeft + 95) >= this.domMi.scrollWidth) {
      this.domMw.scrollLeft = 0;
    } else {
      this.domMw.scrollLeft++;
    }
    this.timerMarquee = requestAnimationFrame(this.landscapeMarquee);
  }

  //竖向滚动
  verticalMarquee = () => {
    //记录当前时间
    nowTime = Date.now()
    // 当前时间-上次执行时间如果大于diffTime，那么执行动画，并更新上次执行时间
    if(nowTime-lastTime > diffTime){
        lastTime = nowTime
        this.verticalRun()
    }
    this.timerMarquee = requestAnimationFrame(this.verticalMarquee);
  }


  // 真实运动
  verticalRun () {
    this.domMw.scrollTop >= this.domMi.scrollHeight ? this.domMw.scrollTop = 0 : this.domMw.scrollTop++;
  }

  // 运动
  runMarquee = () => {
    this.stopMarquee();
    if (this.props.direction === 'vertical') {
      this.timerMarquee = requestAnimationFrame(this.verticalMarquee);
    } else {
      this.timerMarquee = requestAnimationFrame(this.landscapeMarquee);
    }
  }

  //暂停
  stopMarquee = () => {
    this.timerMarquee && cancelAnimationFrame(this.timerMarquee)
  }

  componentDidMount = () => {
    this.initMarquee();
    let { getMarquee } = this.props

    getMarquee && getMarquee({
      runMarquee: this.runMarquee,
      stopMarquee: this.stopMarquee
    });

  }

  componentWillUnmount = () => {
    this.stopMarquee();
  }

  renderLandscapeMarquee() {
    let { loopData } = this.props;
    let itemW = 'auto';
    this.docW = 'auto';

    return (
      <div className="marquee-landscape-wrap" data-scroll={this.domMw && this.domMw.scrollLeft} ref={(mw) => { this.domMw = mw; }}>
        <div className="marquee-landscape-item" style={{width: this.docW}} ref={(mi) => { this.domMi = mi; }}>
          {loopData && loopData.map((item, index) => (<div style={{width: itemW, padding: '0 15px', margin: "0 10px"}} className="marquee-landscape-txt" key={index}><figure className="image is-128x128"><Lazyload placeholder={<LazyLoadSvg />}><img style={{ position:'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) scale(1.35)' }} alt={item.url} src={item.url}/></Lazyload></figure></div>))}
        </div>
        <div className="marquee-landscape-item" style={{width: this.docW}}>
          {loopData && loopData.map((item, index) => (<div style={{width: itemW, padding: '0 15px', margin: "0 10px"}} className="marquee-landscape-txt" key={index}><figure className="image is-128x128"><Lazyload placeholder={<LazyLoadSvg />}><img style={{ position:'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) scale(1.35)' }} alt={item.url} src={item.url}/></Lazyload></figure></div>))}
        </div>
      </div>
    )
  }

  renderLandscapeComponentMarquee() {
    let { loopData, verticalItemHeight } = this.props;
    let itemW = 'auto';
    this.docW = 'auto';

    return (
      <div className="marquee-landscape-wrap" data-scroll={this.domMw && this.domMw.scrollLeft} ref={(mw) => { this.domMw = mw; }}>
        <div className="marquee-landscape-item" style={{width: this.docW}} ref={(mi) => { this.domMi = mi; }}>
          {loopData && loopData.map((item, index) => (<div style={{width: itemW, padding: '0 15px', margin: "0 10px"}} className="marquee-landscape-txt" key={index}>{item}</div>))}
        </div>
        <div className="marquee-landscape-item" style={{width: this.docW}}>
        {loopData && loopData.map((item, index) => (<div style={{width: itemW, padding: '0 15px', margin: "0 10px"}} className="marquee-landscape-txt" key={index}>{item}</div>))}
        </div>
      </div>
    )
  }

  renderVerticalMarquee() {
    let { loopData, verticalItemHeight } = this.props;
    return (
      <div className="marquee-vertical-wrap" data-scroll={this.domMw && this.domMw.scrollLeft} ref={(mw) => { this.domMw = mw; }}>
        <div className="marquee-vertical-item" ref={(mi) => { this.domMi = mi; }}>
          {loopData && loopData.map((item, index) => (<div style={{height: verticalItemHeight}} key={index}>{item}</div>))}
        </div>
        <div className="marquee-vertical-item">
          {loopData && loopData.map((item, index) => (<div style={{height: verticalItemHeight}} key={index}>{item}</div>))}
        </div>
      </div>
    )
  }

  render() {

    let { direction } = this.props;
    // console.log(direction)
    return (
      <div className="react-marquee-box" style={{ position: `${direction === 'landscape' ? 'absolute' :direction === 'landscapecomponent' ? 'absolute' :'static'}`}}>
        {direction === 'landscape' ? this.renderLandscapeMarquee() : direction === 'landscapecomponent' ? this.renderLandscapeComponentMarquee() : this.renderVerticalMarquee()}
      </div>
    )
  }
}

Marquee.propTypes = {
  loop: PropTypes.bool,
  loopData: PropTypes.array,
  getMarquee: PropTypes.func,
  direction: PropTypes.string,
  verticalItemHeight: PropTypes.string
};
