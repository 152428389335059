import React from 'react'
import('./head-info.sass')


const HeadInfo = () => {
  return (
    <div id="head-info" className="appic-publish-head-info is-flex">
      <div className="container">
        <div className="section">
          <div className="appic-publish-head-info_title is-uppercase">
            <p className='is-size-2-mobile'>Got Scale?</p>
            <p className='is-size-2 is-size-5-mobile'>If you have the game, we have the people.</p>
          </div>
          <div className="appic-publish-head-info_desc has-text-centered">
            <p className='is-size-4 is-size-7-mobile'>Our seasoned team has led deployment, growth, and monetization of top grossing, free-to-play mobile games for close to half a billion installs. We try to give every game its best chance to perform and find an audience that pays well. Maybe yours will be our next mega-hit!!</p>
          </div>
        </div>
        <div className="appic-publish-head-info_banners">
          <img src={require('../../images/banner-1.png')} alt="banner-1"/>
          </div>
      </div>
    </div>
  )
}

export default HeadInfo
