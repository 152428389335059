import React from 'react'
import { navigate } from "@reach/router"
import('./TermsContent.sass')


const PrivacyContent = () => {

    const toContentUs = () => {
      if (window.location.pathname !== "/about/") {
        navigate("/about/")
        setTimeout(() => {
          const rectTop = document.querySelector('div[name="#get-in-touch"]')
            .offsetTop
          window.scrollTo({
            top: rectTop,
            behavior: "smooth",
          })
        }, 200)
      } else {
        const rectTop = document.querySelector('div[name="#get-in-touch"]').offsetTop
        window.scrollTo({
          top: rectTop,
          behavior: "smooth",
        })
      }
    }


    return (
      <div id='appic-PrivacyContent' className="appic-privacy-policy-content">
        <div className="container is-over-header">
          <div className="section is-paddingless-l is-paddingless-r">
            <div className="is-size-1 is-font-bebaskai appic-privacy-policy-content-title">用户协议</div>
            <div className="appic-privacy-policy-content-desc is-font-ubuntu">
              {/* Last Updated: Janurary 9, 2020<br /> */}
              <p className="p-list-item">《北京幕悦科技有限公司用户协议》（以下简称“本协议”）由您与北京幕悦科技有限公司共同缔结，本协议具有合同效力。为保障您的合法权益，请您在同意并接受本协议所有条款前务必审慎阅读、充分理解各条款内容，特别是免除或限制北京幕悦科技有限公司责任的条款（以下称“免责条款”）、对您的权利进行限制的条款（以下称“限制条款”）、约定争议解决方式和司法管辖的条款。前述免责、限制及争议解决方式、管辖及其他重要条款将以黑体加粗的方式或其他合理方式提示您注意，该等条款包括但不限于本协议第三条、第四条、第五条、第七条、第八条、第十四条等相关条款，您对该等条款的确认将可能导致您在特定情况下的被动、不便、损失，请您在确认同意本协议之前或在使用北京幕悦科技有限公司游戏服务之前再次阅读前述条款。双方确认前述条款并非属于《合同法》第40条规定的“免除其责任、加重对方责任、排除对方主要权利的”的条款，并同意该条款的合法性及有效性。</p>

              <p className="p-list-item">如果您未满18周岁，请在法定监护人的陪同下阅读本协议。如您为未成年人的法定监护人，请特别注意第5.11条、5.12条及第十二条。</p>
              <p className="p-list-item">如果您对本协议或北京幕悦科技有限公司游戏服务有意见或建议，可与北京幕悦科技有限公司客户服务部门联系，我们会给予您必要的帮助。一旦您点击“同意”键、“接受”键或安装、使用、注册或以其他方式访问我们的服务，即视为您已详细阅读理解了本协议的全部内容，并同意遵守本协议的规定。</p>
              <p className='appic-privacy-policy-content-desc-bt'>一、定义</p>
              <p>如无特别说明，下列术语在本协议中的含义为：</p>
              <p className="p-list-item">1.1本协议：指本协议正文、游戏规则及其修订版本。上述内容一经正式发布，即成为本协议不可分割的组成部分。本协议同时还包括文化部《网络游戏管理暂行办法》（文化部令第49号）制定的《网络游戏服务格式化协议必备条款》。如本协议与《网络游戏服务格式化协议必备条款》中的任何条款相冲突，冲突内容以《网络游戏服务格式化协议必备条款》中的约定为准。</p>
              <p className="p-list-item">1.2游戏规则：指北京幕悦科技有限公司不时发布并修订的关于北京幕悦科技有限公司游戏的用户守则、玩家条例、游戏公告、提示及通知等内容。</p>
              <p className="p-list-item">1.3北京幕悦科技有限公司游戏：指由北京幕悦科技有限公司负责运营的游戏的统称，包括计算机客户端游戏、网页游戏、HTML5游戏（H5游戏）、移动终端游戏、电视端游戏以及其他形式的游戏；北京幕悦科技有限公司游戏可能以软件形式提供，这种情况下，北京幕悦科技有限公司游戏还包括该相关软件及相关文档。</p>
              <p className="p-list-item">1.4北京幕悦科技有限公司游戏服务：指北京幕悦科技有限公司向您提供的与游戏相关的各项运营服务。</p>
              <p className="p-list-item">1.5您：又称“玩家”或“用户”，指被授权使用北京幕悦科技有限公司产品及其服务的自然人。</p>
              <p className="p-list-item">1.6游戏数据：指您在使用北京幕悦科技有限公司游戏过程中产生的被服务器记录的各种数据，包括但不限于角色数据、虚拟物品数据、行为日志、购买日志等数据。</p>

              <p className='appic-privacy-policy-content-desc-bt'>二、协议的变更和生效</p>
              <p className="p-list-item">2.1 北京幕悦科技有限公司有权在必要时变更本协议条款，您可以在北京幕悦科技有限公司游戏的相关页面查阅最新版本的协议条款。</p>
              <p className="p-list-item">2.2 本协议条款变更后，如果您继续使用北京幕悦科技有限公司游戏服务，即视为您已接受变更后的协议。如果您不接受变更后的协议，应当停止使用北京幕悦科技有限公司游戏服务。</p>

              <p className='appic-privacy-policy-content-desc-bt'>三、游戏账号</p>
              <p className="p-list-item">3.1 您如果需要使用和享受北京幕悦科技有限公司游戏，则您必须完全同意本协议条款，按照《网络游戏管理暂行规定》及文化部《网络游戏服务格式化协议必备条款》等规定的要求，登录实名注册系统并按照注册页面的要求完成注册程序，包括但不限于输入正确的基本资料和信息。 您进行实名注册时，应提供有关您本人真实、合法、准确、有效的身份信息及其他相关信息，且不得以他人身份资料进行实名注册。否则，北京幕悦科技有限公司有权终止为您提供北京幕悦科技有限公司游戏服务，并有权对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏账号全部或部分功能、删除游戏账号及游戏数据、删除相关信息、封禁游戏账号（以下简称“封号”）直至注销账号的处理措施（为描述方便，下文有时也将上述处理措施称为“处罚”），因此造成的一切后果由您自行承担。</p>
              <p>您不进行实名注册的，或您提供的注册信息不完整的，则您可能无法使用北京幕悦科技有限公司游戏或在使用北京幕悦科技有限公司游戏过程中会受到相应限制。</p>
              <p className="p-list-item">3.2如果您选择通过“快速游戏”、“游客”或其他类似便捷模式（下称“快速模式”，该模式下您可能无法进行游戏充值或消费），在未注册游戏账号的前提下快速登录游戏的，请您登录后及时绑定游戏账号；如您未绑定游戏账号，则一旦您卸载、重装北京幕悦科技有限公司游戏软件，您更换移动智能设备或您的移动智能设备、电脑损坏导致北京幕悦科技有限公司游戏无法正常启动的，您在快速模式下的全部游戏数据、充值记录可能会被清空，且无法查询、恢复。如因此造成您任何损失的，均由您自行承担。</p>
              <p>如您使用北京幕悦科技有限公司认可的第三方帐号作为游戏账号使用和享受北京幕悦科技有限公司游戏的，您还应遵守有关该第三方帐号的协议、规则，且因该第三方帐号产生的相关问题包括但不限于被盗等，您应自行联系该第三方进行解决，北京幕悦科技有限公司可视情况提供相应的协助。</p>
              <p className="p-list-item">3.3您充分理解并同意，北京幕悦科技有限公司会按照国家相关要求将您的实名注册信息运用于防沉迷系统之中，即北京幕悦科技有限公司可能会根据您的实名注册信息判断您是否年满18周岁、您提交的实名身份信息是否规范或实名验证是否通过等，从而决定是否对您的游戏账号予以防沉迷限制。</p>
              <p className="p-list-item">3.4您充分理解并同意，北京幕悦科技有限公司有权审查您注册时所提供的身份信息是否真实、有效，以便采取技术与管理等合理措施保障游戏账号的安全、有效。您应妥善保管您的游戏账号及所对应的密码，您应在知晓您的游戏账号被盗用后第一时间通知北京幕悦科技有限公司。</p>
              <p>若您发现有他人冒用或盗用您的游戏账号及密码、或任何其他未经您合法授权使用的情形时，应立即以北京幕悦科技有限公司要求的有效方式通知北京幕悦科技有限公司并告知北京幕悦科技有限公司需采取的措施。您通知北京幕悦科技有限公司时，应提供与您注册身份信息相一致的个人有效身份信息，北京幕悦科技有限公司收到您的有效请求并核实身份后，会根据您的要求或结合具体情况采取相应措施（包括但不限于暂停该账号的登录和使用等），北京幕悦科技有限公司因根据您的请求采取相应措施而造成您及其他用户损失的，由您自行承担。若您没有提供有效身份信息或您提供的个人有效身份信息与所注册的身份信息不一致的，北京幕悦科技有限公司有权拒绝您的请求，因此造成您损失的，由您自行承担。</p>
              <p className="p-list-item">3.5您充分理解并同意，为提高北京幕悦科技有限公司游戏服务的安全水平，北京幕悦科技有限公司有权将有关技术或软件应用到北京幕悦科技有限公司游戏中，但北京幕悦科技有限公司不保证这些安全保障措施可以完全杜绝游戏账号被他人窃取或丢失的风险。</p>
              <p className="p-list-item">3.6您充分理解并同意，为高效利用服务器资源，如果您长期未使用游戏账号登录北京幕悦科技有限公司游戏，北京幕悦科技有限公司有权视需要，在提前通知的情况下，对该游戏账号及其账号下的游戏数据及相关信息采取删除等处置措施，上述处置可能导致您对该游戏账号下相关权益的丧失，对此北京幕悦科技有限公司不承担任何责任。</p>
              <p className="p-list-item">3.7您理解并同意，您不得将游戏账号以任何方式提供给他人使用，包括但不限于不得以转让、出租、借用等方式提供给他人作包括但不限于直播、录制、代打代练等商业性使用。否则，因此产生任何法律后果及责任均由您自行承担，且北京幕悦科技有限公司有权对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏帐号全部或部分功能、删除游戏账号及游戏数据及其他相关信息、封号直至注销的处理措施，因此造成的一切后果由您自行承担。</p>

              <p className='appic-privacy-policy-content-desc-bt'>四、用户信息收集、使用及保护</p>
              <p className="p-list-item">4.1您同意并授权北京幕悦科技有限公司为履行本协议之目的收集您的用户信息，这些信息包括您在实名注册系统中注册的信息、您游戏账号下的游戏数据以及其他您在使用北京幕悦科技有限公司游戏服务的过程中向北京幕悦科技有限公司提供或北京幕悦科技有限公司基于安全、用户体验优化等考虑而需收集的信息，北京幕悦科技有限公司对您的用户信息的收集将遵循本协议及相关法律的规定。</p>
              <p className="p-list-item">4.2 您充分理解并同意：北京幕悦科技有限公司或其合作的第三方可以根据您提供的用户信息，通过短信、电话、邮件等各种方式向您提供关于北京幕悦科技有限公司游戏的活动信息、推广信息等各类信息。</p>
              <p className="p-list-item">4.3 您理解并同意：为了更好地向您提供游戏服务，改善游戏体验，北京幕悦科技有限公司可对您游戏账号中的昵称、头像以及在北京幕悦科技有限公司游戏中的相关操作信息、游戏信息等信息（以下称“该等信息”。该等信息具体包括但不限于您的登录状态、对战信息/状态、成就信息等）进行使用，并可向您本人或其他用户或好友展示该等信息。</p>
              <p className="p-list-item">4.4 您应对通过北京幕悦科技有限公司游戏及相关服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。</p>
              <p className="p-list-item">4.5 保护用户信息及隐私是北京幕悦科技有限公司的一项基本原则。除本协议另有规定外，北京幕悦科技有限公司游戏服务对用户信息收集、使用及保护等将遵循北京幕悦科技有限公司统一公布的相关隐私政策。</p>

              <p className='appic-privacy-policy-content-desc-bt'>五、北京幕悦科技有限公司游戏服务</p>
              <p className="p-list-item">5.1在您遵守本协议及相关法律法规的前提下，北京幕悦科技有限公司给予您一项个人的、不可转让及非排他性的许可，以使用北京幕悦科技有限公司游戏服务。您仅可为非商业目的使用北京幕悦科技有限公司游戏服务，包括：</p>
              <p className="p-list-item">（1）接收、下载、安装、启动、升级、登录、显示、运行和/或截屏北京幕悦科技有限公司游戏；</p>
              <p className="p-list-item">（2）创建游戏角色，设置网名，查阅游戏或应用规则、用户个人资料、游戏对战结果，开设游戏房间、设置游戏参数，在游戏中购买、使用游戏道具、游戏装备、游戏币等，使用聊天功能、社交分享功能；</p>
              <p className="p-list-item">（3）使用北京幕悦科技有限公司游戏支持并允许的其他某一项或几项功能。</p>

              <p className="p-list-item">5.2 您在使用北京幕悦科技有限公司游戏服务过程中不得未经北京幕悦科技有限公司许可以任何方式展示、录制、直播或向他人传播北京幕悦科技有限公司游戏内容及游戏画面，包括但不限于不得利用任何第三方软件进行网络传播等。</p>
              <p className="p-list-item">5.3在北京幕悦科技有限公司游戏以软件形式提供的情况下，您在使用北京幕悦科技有限公司游戏及北京幕悦科技有限公司游戏服务时还应符合本协议第六条关于软件许可的规定。</p>
              <p className="p-list-item">5.4本条及本协议其他条款未明示授权的其他一切权利仍由北京幕悦科技有限公司保留，您在行使这些权利时须另外取得北京幕悦科技有限公司的书面许可。</p>
              <p className="p-list-item">5.5如果北京幕悦科技有限公司发现或收到他人举报或投诉您违反本协议约定，北京幕悦科技有限公司有权采取相应的措施进行处理，该措施包括但不限于：不事先通知您而随时对相关内容进行删除，并视行为情节对违规游戏账号处以包括但不限于警告、限制或禁止使用全部或部分功能、封号直至注销的处罚，并公告处理结果，要求您赔偿因您从事违约行为而给北京幕悦科技有限公司造成的损失等。</p>
              <p className="p-list-item">5.6您充分理解并同意，北京幕悦科技有限公司有权依合理判断对违反有关法律法规或本协议规定的行为进行处理，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</p>
              <p className="p-list-item">5.7您充分理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；北京幕悦科技有限公司因此遭受损失的，您也应当一并赔偿。</p>
              <p className="p-list-item">5.8 北京幕悦科技有限公司有权自主决定北京幕悦科技有限公司游戏及北京幕悦科技有限公司游戏服务的收费方式和资费标准。您充分理解并同意：游戏道具、游戏装备、游戏币等是北京幕悦科技有限公司游戏服务的一部分，北京幕悦科技有限公司在此许可您依本协议而获得其使用权。您购买、使用游戏道具、游戏装备、游戏币等应遵循本协议、游戏具体规则的要求；同时，游戏道具、游戏装备、游戏币等可能受到一定有效期限的限制，即使您在规定的有效期内未使用，除不可抗力或可归责于北京幕悦科技有限公司的原因外，一旦有效期届满，将会自动失效。如游戏终止运营的，该有效期截止到运营终止之日。</p>
              <p>您充分理解并同意：为更好地向用户提供北京幕悦科技有限公司游戏服务，北京幕悦科技有限公司有权对游戏中的任何内容或构成元素等作出调整、更新或优化（包括但不限于您购买或正在使用的角色、游戏装备及其他游戏道具的性能及相关数值设置等作出调整、更新或优化。且如北京幕悦科技有限公司做出相应调整、更新或优化等），您同意不会因此追究北京幕悦科技有限公司的任何法律责任。</p>
              <p className="p-list-item">5.9 您充分理解并同意：为保障您游戏账号安全，为营造公平、健康的游戏环境，在您使用北京幕悦科技有限公司游戏服务的过程中，在不违反相关法律规定的情况下，北京幕悦科技有限公司可以通过技术手段了解您终端设备的随机存储内存以及与北京幕悦科技有限公司游戏同时运行的相关程序。一经发现有任何未经授权的、危害北京幕悦科技有限公司游戏服务正常运营的相关程序，北京幕悦科技有限公司可以收集所有与此有关的信息并采取合理措施予以打击。</p>
              <p className="p-list-item">5.10您充分理解并同意：为了保证您及其他用户的游戏体验，北京幕悦科技有限公司有权定期转移或者清除北京幕悦科技有限公司游戏服务器上存储的一些过往的游戏数据。</p>
              <p className="p-list-item">5.11 北京幕悦科技有限公司将按照相关法律法规和本协议的规定，采取切实有效的措施保护未成年人在使用北京幕悦科技有限公司游戏服务过程中的合法权益，包括可能采取技术措施、禁止未成年人接触不适宜的游戏或者游戏功能、限制未成年人的游戏时间、预防未成年人沉迷网络。作为游戏规则的一部分，北京幕悦科技有限公司还将在适当位置发布北京幕悦科技有限公司游戏用户指引和警示说明，包括游戏内容介绍、正确使用游戏的方法以及防止危害发生的方法。所有未成年人用户都应在法定监护人的指导下仔细阅读并遵照执行这些指引和说明；其他玩家在使用北京幕悦科技有限公司游戏服务的过程中应避免发布、产生任何有损未成年人身心健康的内容，共同营造健康游戏环境。</p>
              <p className="p-list-item">5.12 如果您未满18周岁的，为保障您的合法权益，北京幕悦科技有限公司有权依据国家有关法律法规及政策规定、本协议其他条款规定或根据您法定监护人的合理要求采取以下一种或多种措施：</p>

              <p className="p-list-item">（1）将与您游戏相关的信息（包括但不限于您游戏账号的登录信息、充值流水信息等）提供给您的法定监护人，使得您法定监护人可及时或同步了解您游戏情况；</p>
              <p className="p-list-item">（2）限制您游戏账号的消费额度；</p>
              <p className="p-list-item">（3）采取技术措施屏蔽某些游戏或游戏的某些功能，或限定您游戏时间或游戏时长；</p>
              <p className="p-list-item">（4）注销或删除您游戏账号及游戏数据等相关信息；</p>
              <p className="p-list-item">（5）您法定监护人要求采取的，或北京幕悦科技有限公司认为可采取的其他合理措施，以限制或禁止您使用北京幕悦科技有限公司游戏。</p>

              <p className="p-list-item">5.13北京幕悦科技有限公司向用户提供游戏服务本身属于商业行为，用户有权自主决定是否根据北京幕悦科技有限公司自行确定的收费项目（包括但不限于购买游戏内的虚拟道具的使用权以及接受其他增值服务等各类收费项目）及收费标准支付相应的费用，以获得相应的游戏服务。如您不按相应标准支付相应费用的，您将无法获得相应的游戏服务。 您知悉并同意：收费项目或收费标准的改变、调整是一种正常的商业行为，您不得因为收费项目或收费标准的改变、调整而要求北京幕悦科技有限公司进行赔偿或补偿。</p>
              <p className="p-list-item">5.14在任何情况下，北京幕悦科技有限公司不对因不可抗力导致的您在使用北京幕悦科技有限公司游戏服务过程中遭受的损失承担责任。该等不可抗力事件包括但不限于国家法律、法规、政策及国家机关的命令及其他政府行为或者其它的诸如地震、水灾、雪灾、火灾、海啸、台风、罢工、战争、黑客攻击等不可预测、不可避免且不可克服的事件。</p>
              <p className="p-list-item">5.15北京幕悦科技有限公司游戏可能因游戏软件BUG、版本更新缺陷、第三方病毒攻击或其他任何因素导致您的游戏角色、游戏道具、游戏装备及游戏币等账号数据发生异常。在数据异常的原因未得到查明前，北京幕悦科技有限公司有权暂时冻结该游戏账号；若查明数据异常为非正常游戏行为所致，北京幕悦科技有限公司有权恢复游戏账号数据至异常发生前的原始状态（包括向第三方追回被转移数据），且北京幕悦科技有限公司无须向您承担任何责任。</p>
              <p className="p-list-item">5.16北京幕悦科技有限公司未授权您从任何第三方通过购买、接受赠与或者其他的方式获得游戏账号、游戏道具、游戏装备、游戏币等，北京幕悦科技有限公司不对第三方交易的行为负责，并且不受理因任何第三方交易发生纠纷而带来的申诉。</p>
              <p className="p-list-item">5.17您充分理解到：不同操作系统之间存在不互通的客观情况，该客观情况并非北京幕悦科技有限公司造成，由此可能导致您在某一操作系统中的充值和游戏数据不能顺利转移到另一操作系统中。由于您在不同系统进行切换造成的充值损失和游戏数据丢失风险应由您自行承担，北京幕悦科技有限公司对此不承担任何责任。</p>
              <p className="p-list-item">5.18您充分理解到：北京幕悦科技有限公司游戏中可能会设置强制对战区域或玩法，如果您不同意强制对战，请您不要进入该游戏或游戏区域；您的进入，将被视为同意该玩法并接受相应后果。</p>
              <p className="p-list-item">5.19北京幕悦科技有限公司自行决定终止运营北京幕悦科技有限公司游戏时或北京幕悦科技有限公司游戏因其他任何原因终止运营时，北京幕悦科技有限公司会按照文化部有关网络游戏终止运营的相关规定处理游戏终止运营相关事宜，以保障用户合法权益。</p>
              <p className="p-list-item">5.20北京幕悦科技有限公司保留在其认为有必要的情况下，终止或部分终止提供北京幕悦科技有限公司游戏服务的权利，终止前将提前60天予以公告。不管由于何种原因导致北京幕悦科技有限公司游戏服务终止，用户应采取相应的措施自行处理游戏虚拟物品，包括但不限于注销或停止使用用户帐号、游戏虚拟物品等相关事宜。用户不得因北京幕悦科技有限公司游戏服务全面终止而要求北京幕悦科技有限公司承担除用户已经购买但尚未使用的游戏虚拟货币外任何形式的赔偿或补偿责任，包括但不限于因不再能继续使用用户帐号、游戏虚拟物品等而要求的赔偿。</p>




              <p className='appic-privacy-policy-content-desc-bt'>六、软件许可</p>
              <p className="p-list-item">6.1 使用北京幕悦科技有限公司游戏服务可能需要下载并安装相关软件，您可以直接从北京幕悦科技有限公司的相关网站上获取该软件，也可以从得到北京幕悦科技有限公司授权的第三方获取。如果您从未经北京幕悦科技有限公司授权的第三方获取北京幕悦科技有限公司游戏或与北京幕悦科技有限公司游戏名称相同的游戏，将视为您未获得北京幕悦科技有限公司授权，北京幕悦科技有限公司无法保证该游戏能够正常使用，并对因此给您造成的损失不予负责。</p>
              <p className="p-list-item">6.2 北京幕悦科技有限公司可能为不同的终端设备或操作系统开发了不同的软件版本，包括但不限于Windows、iOS、Android、Windows Phone、Symbian、Blackberry等多个应用版本，您应当根据实际情况选择下载合适的版本进行安装，下载安装程序后，您需要按照该程序提示的步骤正确安装。</p>
              <p className="p-list-item">6.3 若北京幕悦科技有限公司游戏以软件形式提供，北京幕悦科技有限公司给予您一项个人的、非商业使用的、可撤销的、可变更的、不可转让、不可转授权及非排他性的许可。您仅可为非商业目的在终端设备上下载、安装、登录、使用该北京幕悦科技有限公司游戏。</p>
              <p>如您有需要在个人使用的范围以外使用北京幕悦科技有限公司游戏及北京幕悦科技有限公司游戏服务或者将北京幕悦科技有限公司游戏与北京幕悦科技有限公司游戏服务用于任何商业用途，则应与北京幕悦科技有限公司联系并获得北京幕悦科技有限公司另行授权。任何未经北京幕悦科技有限公司许可的安装、使用、访问、显示、运行、商业使用等行为均属对北京幕悦科技有限公司的侵权。</p>
              <p className="p-list-item">6.4 为提供更加优质、安全的服务，在软件安装时北京幕悦科技有限公司可能推荐您安装其他软件，您可以选择安装或不安装。</p>
              <p className="p-list-item">6.5 如果您不再需要使用该软件或者需要安装新版，可以自行卸载。如果您愿意帮助北京幕悦科技有限公司改进产品服务，请告知卸载的原因。</p>
              <p className="p-list-item">6.6 为了保证北京幕悦科技有限公司游戏服务的安全性和功能的一致性，北京幕悦科技有限公司有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。</p>
              <p className="p-list-item">6.7 软件新版本发布后，旧版本的软件可能无法使用。北京幕悦科技有限公司不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。</p>


              <p className='appic-privacy-policy-content-desc-bt'>七、用户行为规范</p>
              <p className="p-list-item">7.1 您充分了解并同意，您必须为自己游戏账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对北京幕悦科技有限公司游戏中的内容自行加以判断，并承担因使用北京幕悦科技有限公司游戏服务而引起的所有风险，包括因对北京幕悦科技有限公司游戏内容的正确性、完整性或实用性的依赖而产生的风险。北京幕悦科技有限公司无法且不会对因前述风险而导致的任何损失或损害承担责任。</p>
              <p className="p-list-item">7.2 您除了可以按照本协议的约定使用北京幕悦科技有限公司游戏服务之外，不得进行任何侵犯北京幕悦科技有限公司游戏的知识产权的行为，或者进行其他的有损于北京幕悦科技有限公司或其他第三方合法权益的行为。</p>
              <p className="p-list-item">7.3 您在使用北京幕悦科技有限公司游戏或北京幕悦科技有限公司游戏服务时须遵守法律法规，不得利用北京幕悦科技有限公司游戏或北京幕悦科技有限公司游戏服务从事违法违规行为，包括但不限于以下行为：</p>
              <p className="p-list-item">（1）违反宪法确定的基本原则的；</p>
              <p className="p-list-item">（2）危害国家统一、主权和领土完整的；</p>
              <p className="p-list-item">（3）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</p>
              <p className="p-list-item">（4）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；</p>
              <p className="p-list-item">（5）宣扬邪教、迷信的；</p>
              <p className="p-list-item">（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
              <p className="p-list-item">（7）宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；</p>
              <p className="p-list-item">（8）侮辱、诽谤他人，侵害他人合法权益的；</p>
              <p className="p-list-item">（9）违背社会公德的；</p>
              <p className="p-list-item">（10）有法律、行政法规和国家规定禁止的其他内容的。</p>

              <p className="p-list-item">7.4 除非法律允许或北京幕悦科技有限公司书面许可，您不得从事下列行为：</p>
              <p className="p-list-item">（1）删除北京幕悦科技有限公司游戏软件及其副本上关于著作权的信息；</p>
              <p className="p-list-item">（2）对北京幕悦科技有限公司游戏软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现北京幕悦科技有限公司软件的源代码；</p>
              <p className="p-list-item">（3）对北京幕悦科技有限公司游戏软件进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；</p>
              <p className="p-list-item">（4）对北京幕悦科技有限公司游戏软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；</p>
              <p className="p-list-item">（5）修改或伪造北京幕悦科技有限公司软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；</p>
              <p className="p-list-item">（6）通过非北京幕悦科技有限公司开发、授权的第三方软件、插件、外挂、系统，使用北京幕悦科技有限公司游戏及北京幕悦科技有限公司游戏服务，或制作、发布、传播非北京幕悦科技有限公司开发、授权的第三方软件、插件、外挂、系统；</p>
              <p className="p-list-item">（7）对游戏中北京幕悦科技有限公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
              <p className="p-list-item">（8）建立有关北京幕悦科技有限公司游戏的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与北京幕悦科技有限公司游戏服务完全相同或者类似的服务；</p>
              <p className="p-list-item">（9）将北京幕悦科技有限公司游戏的任意部分分离出来单独使用，或者进行其他的不符合本协议的使用；</p>
              <p className="p-list-item">（10）使用、修改或遮盖北京幕悦科技有限公司游戏的名称、商标或其它知识产权；</p>
              <p className="p-list-item">（11）其他未经北京幕悦科技有限公司明示授权的行为。</p>

              <p className="p-list-item">7.5 您在使用北京幕悦科技有限公司游戏服务过程中有如下任何行为的，北京幕悦科技有限公司有权视情节严重程度，依据本协议及相关游戏规则的规定，对您做出暂时或永久性地封号、删除游戏账号及游戏数据、收回游戏虚拟物品、删除相关信息等处理措施，北京幕悦科技有限公司并保留追究您的法律责任的权利：</p>
              <p className="p-list-item">（1）以某种方式暗示或伪称北京幕悦科技有限公司内部员工或某种特殊身份，企图得到不正当利益或影响其他用户权益的行为；</p>
              <p className="p-list-item">（2）在北京幕悦科技有限公司游戏中使用非法或不当词语、字符等，包括用于角色命名；</p>
              <p className="p-list-item">（3）以任何方式破坏北京幕悦科技有限公司游戏或影响北京幕悦科技有限公司游戏服务的正常进行；</p>
              <p className="p-list-item">（4）各种非法外挂行为；</p>
              <p className="p-list-item">（5）传播非法言论或不当信息；</p>
              <p className="p-list-item">（6）盗取他人游戏账号、游戏物品；</p>
              <p className="p-list-item">（7）私自进行游戏账号交易；</p>
              <p className="p-list-item">（8）私自进行游戏虚拟货币、游戏装备、游戏币及其他游戏道具等交易；</p>
              <p className="p-list-item">（9）利用bug或非正常游戏方式提升游戏角色数据、游戏内的角色排名的行为；</p>
              <p className="p-list-item">（10）违反本协议任何约定的；</p>
              <p className="p-list-item">（11）其他在行业内被广泛认可的不当行为，无论是否已经被本协议或游戏规则明确列明。</p>
              <p>您知悉并同意，由于外挂具有隐蔽性或用完后即消失等特点，北京幕悦科技有限公司有权根据您的游戏数据和表现异常判断您有无使用非法外挂等行为。</p>

              <p className="p-list-item">7.6 您知悉并同意，如北京幕悦科技有限公司依据本协议对您的游戏账号采取封号处理措施的，具体封号期间由北京幕悦科技有限公司根据您违规行为情节而定。</p>
              <p className="p-list-item">您知悉并同意：</p>
              <p className="p-list-item">（1）在封号期间，您游戏账号中的游戏虚拟货币、游戏装备、游戏币及其他游戏道具可能都将无法使用；</p>
              <p className="p-list-item">（2）如前述游戏虚拟货币、游戏装备、游戏币及其他游戏道具存在一定有效期，该有效期可能会在封号期间过期，您游戏账号解封后，您将无法使用该等已过期的游戏虚拟货币、游戏装备、游戏币及其他游戏道具。</p>
              <p className="p-list-item">据此，您也同意不会因发生前述第（1）和（或）第（2）点规定的情形而追究北京幕悦科技有限公司任何法律责任。</p>



              <p className='appic-privacy-policy-content-desc-bt'>八、免责声明</p>
              <p className="p-list-item">8.1 北京幕悦科技有限公司游戏服务以“现状”提供给您。北京幕悦科技有限公司不保证：北京幕悦科技有限公司游戏服务无错误及不会中断、所有缺陷已被更正、或北京幕悦科技有限公司游戏服务不会受到病毒或任何其它因素的损害。除非有法律明确规定，北京幕悦科技有限公司在此明确声明不承担任何明示或默示的担保责任，包括但不限于对北京幕悦科技有限公司游戏服务的性能、适用性或不侵权的担保。</p>
              <p className="p-list-item">8.2 您充分理解到：第三方在北京幕悦科技有限公司游戏中投放的广告、链接或者其它形式的推广内容，均是由其自行提供的，您应当自行判断其真实性，北京幕悦科技有限公司对其推广内容不作任何明示或者默示的担保。</p>


              <p className='appic-privacy-policy-content-desc-bt'>九、知识产权</p>
              <p className="p-list-item">9.1北京幕悦科技有限公司依法享有北京幕悦科技有限公司游戏软件的一切合法权益（包括但不限于其中涉及的计算机软件著作权、美术作品著作权、商标权、专利权等知识产权）；与北京幕悦科技有限公司游戏相关的所有信息内容（包括文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）及商业秘密均归北京幕悦科技有限公司所有。北京幕悦科技有限公司有权运营该等北京幕悦科技有限公司游戏软件，或已从合法权利人处取得合法授权，并有权为用户提供北京幕悦科技有限公司游戏软件及北京幕悦科技有限公司游戏服务。未经北京幕悦科技有限公司事先书面同意，您不得以任何方式将北京幕悦科技有限公司游戏（包括北京幕悦科技有限公司游戏整体及北京幕悦科技有限公司游戏涉及的所有内容、组成部分或构成要素）进行商业性使用。</p>
              <p className="p-list-item">9.2尽管本协议有其他规定，您在使用北京幕悦科技有限公司游戏服务中产生的账号数据信息、角色数据信息、等级、排行榜数据及虚拟物品数据信息（包括但不限于增值服务币、游戏道具等，但用户的姓名、身份证号、电话号码等个人身份数据信息除外）等游戏数据的所有权和知识产权归北京幕悦科技有限公司所有，并由北京幕悦科技有限公司进行管理，北京幕悦科技有限公司有权在游戏内对玩家的游戏数据进行展示，包括但不限于在游戏排行榜中展示玩家游戏数据（战斗力、等级、竞技场排名等）。北京幕悦科技有限公司有权处置该游戏数据。其中，北京幕悦科技有限公司对用户购买游戏虚拟货币的购买记录的保存期限将遵守文化部《网络游戏管理暂行办法》有关规定。对其他游戏数据的保存期限由北京幕悦科技有限公司自行决定，但国家法律法规另有规定的从其规定。</p>
              <p className="p-list-item">9.3北京幕悦科技有限公司游戏可能涉及第三方知识产权，而该等第三方对您基于本协议在北京幕悦科技有限公司游戏中使用该等知识产权有要求的，北京幕悦科技有限公司将以适当方式向您告知该要求，您应当一并遵守。</p>
              <p className="p-list-item">9.4北京幕悦科技有限公司尊重知识产权并注重保护用户享有的各项权利。在北京幕悦科技有限公司游戏服务中，您可能需要通过上传、发布等各种方式向北京幕悦科技有限公司提供内容。在此情况下，您仍然享有此等内容的完整知识产权。您在此明确同意，您在提供前述内容的同时，不可撤销地授予北京幕悦科技有限公司及其关联公司一项全球性的、永久的免费许可，允许北京幕悦科技有限公司及其关联公司使用、传播、复制、修改、再许可、翻译、出版、表演及展示此等内容，以及使用此等内容创作衍生作品。</p>



              <p className='appic-privacy-policy-content-desc-bt'>十、订阅及自动续订功能</p>
              <p className='p-list-item'>如果您购买或启用了北京幕悦科技有限公司游戏的订阅及自动续订功能，则代表您完全了解并同意以下的相关约定和使用规则：</p>
              <p className="p-list-item">10.1除非在当期订阅期结束的 24 小时前取消订阅，否则您的订阅将会被自动续订。</p>
              <p className="p-list-item">10.2 如果您未取消订阅，在当期订阅期结束前的 24 小时内，您的游戏账户将会被收取下期的订阅费用。</p>
              <p className="p-list-item">10.3 在订阅期内，您不能取消当期的订阅。</p>
              <p className="p-list-item">10.4 如果北京幕悦科技有限公司提供免费试用（试玩）服务，则一旦您购买或启用了订阅功能，您未使用或未到期的试用（试玩）服务也将自您订阅时起自动作废。</p>
              <p className="p-list-item">10.5 如果您需要关闭自动续订功能，您可自行在游戏账号管理页面更改或取消自动续订设定。</p>


              <p className='appic-privacy-policy-content-desc-bt'>十一、单机游戏特别说明</p>
              <p className="p-list-item">如您使用的北京幕悦科技有限公司游戏为单机游戏，充值系统仅在接入互联网或移动通信网（短代支付等）后方可使用，如您使用充值系统进行充值，将通过互联网或移动通信网（短代支付等）确认充值信息。单机游戏的北京幕悦科技有限公司游戏服务中，全部游戏数据将保存在您的移动智能设备上，一旦您卸载、重装北京幕悦科技有限公司游戏软件，或您的移动智能设备损坏导致北京幕悦科技有限公司游戏软件无法正常启动的，您的全部游戏数据将无法恢复。如您需将游戏数据保存在游戏服务器中的，需要联网进行数据保存操作。</p>


              <p className='appic-privacy-policy-content-desc-bt'>十二、未成年人使用北京幕悦科技有限公司的服务</p>
              <p className="p-list-item">12.1北京幕悦科技有限公司非常重视对未成年人个人信息的保护，18周岁以下的未成年人未经父母或法定监护人同意不得使用北京幕悦科技有限公司的服务。如您是18周岁以下的未成年人的父母或法定监护人，则应监督和管控该未成年人使用北京幕悦科技有限公司服务的行为，该未成年人创建账户应通过您的事先同意。</p>


              <p className='appic-privacy-policy-content-desc-bt'>十三、遵守当地法律监管</p>
              <p className="p-list-item">13.1 您在使用北京幕悦科技有限公司游戏服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。</p>
              <p className="p-list-item">13.2 您应避免因使用北京幕悦科技有限公司游戏服务而使北京幕悦科技有限公司卷入政治和公共事件，否则北京幕悦科技有限公司有权暂停或终止对您的服务。</p>


              <p className='appic-privacy-policy-content-desc-bt'>十四、管辖与法律适用</p>
              <p className="p-list-item">14.1 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</p>
              <p className="p-list-item">14.2 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
              <p className="p-list-item">14.3 本协议条款无论因何种原因部分无效，其余条款仍有效，对各方具有约束力。</p>


              <p className='appic-privacy-policy-content-desc-bt'>十五、其他</p>
              <p className="p-list-item">15.1 根据国家关于健康游戏的忠告，北京幕悦科技有限公司提醒您：抵制不良游戏，拒绝盗版游戏；注意自我保护，谨防受骗上当；适度游戏益脑，沉迷游戏伤身。</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  export default PrivacyContent
