import React from "react"
import Swiper from 'react-id-swiper'
import 'swiper/swiper.scss'
import Lazyload from "react-lazyload"
// import Marquee from '../Marquee/Marquee'
import LazyLoadSvg from "../LazyLoad/LazyLoadSvg"
import videojs from 'video.js'
import useUnmount from '../ReactUse/useUnmount'
import useMount from '../ReactUse/useMount'
import('video.js/dist/video-js.css')




const HotGames = ({ appicplay }) => {
  // const { appicplay: { home: { hotGames }, siteProps } } = appicplay;
  const { appicplay: data } = appicplay
  const { home, siteProps } = data;
  const BaseOssUrl = typeof siteProps !== `undefined` && (siteProps.url + siteProps.folder)
  let logoUrl = ``;
  let closeQuotes = ``;
  let openQuotes = ``;
  let gamesPicUrl = ``;
  let desc1 = ``;
  let desc2 = ``;
  let targetUrlDesc = ``;
  let videoUrl = ``;
  let loopData = [];
  if (typeof home !== `undefined`) {
    if (typeof home.hotGames !== `undefined`) {
      logoUrl = home.hotGames.logoUrl;
      videoUrl = home.hotGames.gamesVideo;
      openQuotes = home.hotGames.openQuotes;
      closeQuotes = home.hotGames.closeQuotes;
      if (typeof home.hotGames.desc !== `undefined`) {
        desc1 = home.hotGames.desc.top;
        desc2 = home.hotGames.desc.bottom;
        targetUrlDesc = home.hotGames.desc.targetUrlDesc;
      }

      if (typeof home.hotGames.comment !== `undefined`) {
        let arr = home.hotGames.comment.map(item => {
          return {
            title: item.title,
            content: item.content
          }
        })

        loopData = arr.map((item, i) => {
          return (
              <div className='games-bottom-desc' key={i}>
                <span className="has-text-weight-bold ">
                  - { item.title ? item.title : 'Not Data' }
                </span>
                <p className="is-size-7-mobile">
                  { item.content ? item.content : 'Not Data' }
                </p>
              </div>
            )
        })
      }
    }
  }


  let videoNode, player, isMobile;
  const videoJsOptions = {
    autoplay: true,
    controls: false,
    fluid: true,
    loop: true,
    sources: [{
      src: BaseOssUrl + videoUrl,
      // src: require('../../videos/1.mp4'),
      type: 'video/mp4'
    }]
  }

  useMount(() => {
    player = videojs(videoNode, {...videoJsOptions});

    var sUserAgent = navigator.userAgent;
    if (sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPad') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1) {
      isMobile = true;
    } else {
      isMobile = false;
    }
  })

  useUnmount(() => {
    if (player) {
      player.dispose()
    }
  })

  const params = {
    speed: 600,
    parallax: true,
    loop: true,
    autoplay: {
      delay: 13000,
      disableOnInteraction: false
    },
    parallaxEl: {
      el: '.parallax-bg',
      value: '-23%'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }


  return (
    <div className="appic-main-hot-games">
      <div className="container">
        <div className="appic-main-hot-games-top columns">
          <div className="appic-main-hot-games-tl column is-three-fifths">
            <div>
              <div className="games-tl-img-container  is-two-fifths">
                <Lazyload placeholder={<LazyLoadSvg isCyan={true} />}>
                  <img
                    className="games-tl-img"
                    src={BaseOssUrl + logoUrl}
                    alt="games-hot-logo"
                  />
                </Lazyload>
              </div>
              <div className="games-tl-desc-container">
                <p className="games-tl-desc-item is-size--mobile is-font-ubuntu">
                  {desc1 ? desc1 : 'Not Text...'}
                </p>
                <p className="games-tl-desc-item is-size-7-mobile">
                  <a
                    target="_blank"
                    href="https://www.appicplay.com"
                    rel="noopener noreferrer"
                    className="games-tl-desc-item-a"
                  >
                    {targetUrlDesc ? targetUrlDesc : ''}
                  </a>{" "}
                  { desc2 ? desc2 : 'Not Text...' }
                </p>
              </div>
            </div>
          </div>
          <div className={`appic-main-hot-games-tr column ${isMobile ? 'appic-video-mobile' : ''}`}>
            <div className="games-tr-img-container-phone-wrap">
              <img src={require('../../images/phone-wrap1.png')} alt="1111" />
            </div>
            <div className="games-tr-img-container">
              <div className="vjs oh">
                <div data-vjs-player>
                  <video muted ref={ node => videoNode = node } poster={require('../../images/poster.png')}  className="video-js"></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="appic-main-hot-games-bottom columns">
        <div className="container">
          <div className="is-three-fifths is-offset-1 games-bottom-box">
          <Lazyload placeholder={<LazyLoadSvg isCyan={true} />}>
            <img
              className="open-quotes"
              src={BaseOssUrl + openQuotes}
              alt="open-quotes"
            />
          </Lazyload>
           <div className=" games-bottom-desc is-size-7-mobile is-font-ubuntu">
           
          {/* <div className="is-size-7-mobile is-font-ubuntu"> */}
            {/* <Marquee direction='landscapecomponent'  loopData={loopData}/> */}
           <Swiper {...params}>
             { loopData }
          </Swiper>
          </div>
          <Lazyload placeholder={<LazyLoadSvg isCyan={true} />}>
            <img
              className="close-quotes"
              src={BaseOssUrl + closeQuotes}
              alt="close-quotes"
            />
          </Lazyload>
        </div>
        </div>
      </div>
    </div>
  )
}

export default HotGames
