import React from 'react'
import { navigate } from "@reach/router"
import('./privacy-content.sass')

const PrivacyContent = () => {
  
  const toContentUs = () => {
    if (window.location.pathname !== "/about/") {
      navigate("/about/")
      setTimeout(() => {
        const rectTop = document.querySelector('div[name="#get-in-touch"]')
          .offsetTop
        window.scrollTo({
          top: rectTop,
          behavior: "smooth",
        })
      }, 200)
    } else {
      const rectTop = document.querySelector('div[name="#get-in-touch"]').offsetTop
      window.scrollTo({
        top: rectTop,
        behavior: "smooth",
      })
    }
  }


  return (
    <div id='appic-PrivacyContent' className="appic-privacy-policy-content">
      <div className="container is-over-header">
        <div className="section is-paddingless-l is-paddingless-r">
          <div className="is-size-1 is-font-bebaskai appic-privacy-policy-content-title">PRIVACY POLICY</div>
          <div className="appic-privacy-policy-content-desc is-font-ubuntu">
            Updated 1st, Feb<br />
            IMPORTANT - READ CAREFULLY: Beijing AppicPlay Ltd. ("AppicPlay", "we", or "us") is strongly committed to protecting the privacy of users("you" or "your") of its games and any related services, such as websites, forums and mobile applications(collectively, the "Service(s)" or "Game(s)"). AppicPlay may collect your information when you use our Services. AppicPlay has developed this Privacy Policy to help you understand how AppicPlay collects, uses, shares, discloses, transfers and stores, any information AppicPlay may collect from you and/or your device, as well as the methods that you could access to, control and protect your information.
            <p className="section is-paddingless-lr">If you do not agree to this Privacy Policy or have any doubts, please contact us mailing to <b onClick={toContentUs} className="link-active has-text-weight-normal">contact@appicplay.com.</b></p>
            <p>What Information Do We Collect?</p>
            <p>Information You Give To Us</p>
            <p className="section is-paddingless-lr is-paddingless-b">We receive and store any information you give to us, such as when you:</p>
            <p className="p-list-item">• download, update and play our Games or are involved in our special events (for example, offline campaign);</p>
            <p className="p-list-item">• enter information in our Services;</p>
            <p className="p-list-item">• make an in-app purchase;</p>
            <p className="p-list-item">• interact with us via social media such as Wechat, Weibo, QQ, etc.;</p>
            <p className="p-list-item">• respond to our customer surveys;</p>
            <p className="p-list-item">• subscribe to our newsletters or other information feeds;</p>
            <p className="p-list-item">• request technical support, make a compliment or complaint, or otherwise contact us directly;</p>
            <p className="p-list-item">• give us your information in any other forms.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyContent
