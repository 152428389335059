import React from 'react'
import ReactComment from '../ReactComment/ReactComment'
import TermsContentEn from '../TermsContent/TermsContentEn'
import TermsContentZh from '../TermsContent/TermsContentZh'
import { navigate } from "gatsby"

const TermsOfService = (props) => {
  console.log(props);
  
  if(props.appicplay.search === '?lang=en'){
    return (
      <div id='appic-PrivacyPolicyComponents' className="appic-privacy">
        <ReactComment text={'terms-of-service'} />
        <TermsContentEn />
      </div>
    )
  }else if(props.appicplay.search === '?lang=zh'){
    return (
      <div id='appic-PrivacyPolicyComponents' className="appic-privacy">
        <ReactComment text={'terms-of-service'} />
        <TermsContentZh />
      </div>
    )
  }else{
    if(typeof window !== 'undefined')navigate('/',{replace: true})
    return(
      <div>页面加载失败</div>
    )
  }
}

export default TermsOfService
