import BannerAnim from "rc-banner-anim"
import QueueAnim from "rc-queue-anim"
import React from "react"
import("./swiper.scss")

const { Element } = BannerAnim
const BgElement = Element.BgElement
const SwiperComponent = ({ appicplay }) => {
  // const { appicplay: { home: { banner }, siteProps } } = appicplay;
  const { appicplay: data } = appicplay
  const { home, siteProps } = data;


  return (
    <BannerAnim type="across" arrow={false} autoPlay={home && home.banner.isAutoPlay}>
      {
        home && home.banner.pics.map((item) => {
          return (
            <Element key={item.id} prefixCls="banner-user-elem">
              <BgElement
                key="bg"
                className="bg"
                style={{
                  backgroundImage:
                    `url(${siteProps.url}${siteProps.folder}${item.imgUrl})`,
                }}
              />
              <div className="text-container" style={{    zIndex: 3}}>
                <QueueAnim name="QueueAnim">
                  <h1 key={item.title} className="is-size-4-mobile">
                    {item.title}
                  </h1>
                  <h1 key={item.subTitle} className="is-size-4-mobile">
                    {item.subTitle}
                  </h1>
                </QueueAnim>
              </div>
            </Element>
          )
        })
      }
    </BannerAnim>
  )
}

export default SwiperComponent
