import React, { useEffect } from "react"
import SwiperComponent from "../Swiper/Swiper"
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import("./header.scss")

const Header = ({ location, appicplay }) => {

  useEffect(() => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    )
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener("click", () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target
          const $target = document.getElementById(target)
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle("is-active")
          $target.classList.toggle("is-active")
        })
      })
    }
  })

  const toGames = () => {
    if (location.pathname !== "/") {
      navigate("/")
      setTimeout(() => {
        const rectTop = document.querySelector('div[name="#top-games"]')
          .offsetTop
        window.scrollTo({
          top: rectTop,
          behavior: "smooth",
        })
      }, 200)
    } else {
      const rectTop = document.querySelector('div[name="#top-games"]').offsetTop
      window.scrollTo({
        top: rectTop,
        behavior: "smooth",
      })
    }
  }


  const toContentUs = () => {
    if (location.pathname !== "/about/") {
      navigate("/about/")
      setTimeout(() => {
        const rectTop = document.querySelector('div[name="#get-in-touch"]')
          .offsetTop
        window.scrollTo({
          top: rectTop,
          behavior: "smooth",
        })
      }, 200)
    } else {
      const rectTop = document.querySelector('div[name="#get-in-touch"]').offsetTop
      window.scrollTo({
        top: rectTop,
        behavior: "smooth",
      })
    }
  }

  return (
    <header className="appic-header">
      {location && location.pathname !== "/" ? null : <SwiperComponent appicplay={appicplay} />}
      {/* <ReactComment text={"appic-header-nav-components"} /> */}
      <nav
        className="appic-navbar navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item is-hidden-mobile" href="https://www.appicplay.com">
            <img
              className="is-pc"
              src={require('../../images/appic-icon.png')}
              alt="Logo"
            />
          </a>
          <a className="navbar-item is-hidden-tablet" href="https://www.appicplay.com">
            <img
              className="is-mobile"
              src={require('../../images/logo_nav_mobile.png')}
              alt="Logo"
            />
          </a>
          <div
            className="navbar-burger burger"
            role="button"
            aria-label="menu"
            aria-expanded="false"
            data-target="navMenu"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
        <div id="navMenu" className="navbar-menu">
          <div className="navbar-start">
            <Link to="/" className="navbar-item" activeClassName={"is-active"}>
              HOME
            </Link>
            <div onClick={toGames} className="navbar-item">
              GAMES
            </div>
            <Link
              to="/publish/"
              className="navbar-item"
              activeClassName={"is-active"}
            >
              PUBLISH/MONETIZE
            </Link>
            <Link
              to="/about/"
              className="navbar-item"
              activeClassName={"is-active"}
            >
              ABOUT US
            </Link>
          </div>
          <div onClick={toContentUs} className="navbar-end navbar-item">
            CONTACT US
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
