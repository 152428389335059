import React from 'react'
import ReactComment from '../ReactComment/ReactComment'
import PrivacyContentEn from '../PrivacyContent/PrivacyContentEn'
import PrivacyContentZh from '../PrivacyContent/PrivacyContentZh'
import { navigate } from "gatsby"

const PrivacyPolicyComponents = (props) => {
  if(props.appicplay.search === '?lang=en'){
    return (
      <div id='appic-PrivacyPolicyComponents' className="appic-privacy">
        <ReactComment text={'privacy-policy'} />
        <PrivacyContentEn />
      </div>
    )
  }else if(props.appicplay.search === '?lang=zh'){
    return (
      <div id='appic-PrivacyPolicyComponents' className="appic-privacy">
        <ReactComment text={'privacy-policy'} />
        <PrivacyContentZh />
      </div>
    )
  }else{
    if(typeof window !== 'undefined')navigate('/',{replace: true})
    return(
      <div>页面加载失败</div>
    )
  }
}

export default PrivacyPolicyComponents
