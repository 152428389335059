import React from "react"
import Marquee from '../Marquee/Marquee'
import("./more-company.scss")

const LoopModeWithMultipleSlidesPerGroup = ({appicplay}) => {
  // const { appicplay: { home: { moreCompany }, siteProps } } = appicplay;
    const { appicplay: data } = appicplay
  const { home, siteProps } = data;
  const BaseOSSUrl = siteProps && (siteProps.url + siteProps.folder)
  const loopData = home && home.moreCompany.pics.map(item => {
    return {
      url: BaseOSSUrl + item.imgUrl
    }
  })

  return (
    <div className="appic-more-company">
      <Marquee loopData={loopData} />
    </div>
  )
}

export default LoopModeWithMultipleSlidesPerGroup
