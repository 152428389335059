import React from 'react'
import('./logo-desc.sass')

const LogoDesc = () => {
  return (
    <div id='appic-LogoDesc' className="appic-about-head">
      <div className="container">
        <div className="columns">
          <div className="column is-narrow is-hidden-mobile">
            <figure className="image is-320x320">
              <img src={require('../../images/appic-icon-about.png')} alt="logo"/>
            </figure>
          </div>
          <div className="column is-have-padding">
            <div className="is-font-ubuntu is-size-5 is-size-6-mobile">
              While this company was founded a little more than 3 years ago, most team members have worked together much longer in either gaming or development. Today, the team culture is stronger than ever. We have probably one of the lowest staff turnover rates for a company of this kind in China. It is a work hard, play hard atmosphere, but one built on a platform of mutual respect and a shared mission to succeed for ourselves and our partners. We are also a great blend of East meets West in our thinking and approach to business, and are happy to do business in Chinese, English, German or Spanish.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoDesc
